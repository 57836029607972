import React, { useState } from "react"

import { connect } from "react-redux"
import { Link } from "react-router-dom"

import axios from "axios"

import * as actions from "./../../store/actions"

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  DialogContentText,
  TextField,
  IconButton,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core"

import { ChevronLeft, ChevronRight, Delete } from "@material-ui/icons"
import DeliveryDetailsForm from "./DeliveryDetailsForm"

// const OrderStatusBasket = 1;
const OrderStatusInitiated = 2
const OrderStatusInProcess = 3
const OrderStatusInDelivery = 4
const OrderStatusCancelled = 5
const OrderStatusSuccessfull = 6

const statusString = (statusCode, lang) => {
  switch (statusCode) {
    case OrderStatusInitiated:
      return lang === "ua" ? "очікує обробку" : "ожидает обработку"
    case OrderStatusInProcess:
      return lang === "ua" ? "обробляється" : "обрабатывается"
    case OrderStatusInDelivery:
      return lang === "ua" ? "доставляється" : "доставляется"
    case OrderStatusCancelled:
      return lang === "ua" ? "скасовано" : "отменен"
    case OrderStatusSuccessfull:
      return lang === "ua" ? "доставлено" : "доставлено"
    default:
      break
  }
}

const ActionsDialog = props => {
  const {
    isLoggedIn,
    onClose,
    sessionOrders,
    translation,
    deleteFromBasket,
    sessionBasket,
    onLogOut,
    sessionUser,
    updateProfile,
  } = props

  const SECTION_DEFAULT = 0
  const SECTION_BASKET = 1
  const SECTION_ORDERS_HISTORY = 2
  const SECTION_PROFILE = 3
  const SECTION_LOG_OUT = 4

  const [section, setSection] = useState(props.section || SECTION_DEFAULT)

  // Updates
  const [name, setName] = useState(sessionUser.name)
  const [email, setEmail] = useState(sessionUser.email)
  const [phone, setPhone] = useState(sessionUser.phone)
  const [city, setCity] = useState(sessionUser.city)
  const [department, setDepartment] = useState(sessionUser.department)

  const [payment, setPayment] = useState(sessionUser.payment || 1)

  // eslint-disable-next-line no-unused-vars
  const [password, setPassword] = useState("")
  // eslint-disable-next-line no-unused-vars
  const [editingPassword, setEditingPassword] = useState(false)

  const handleUpdate = updateType => {
    const url = `/profile/update/`
    const data = new FormData()

    data.append("name", name)
    data.append("email", email)
    data.append("phone", phone)
    data.append("city", city.id)
    data.append("department", department.id)
    data.append("password", password)
    data.append("payment", payment)

    axios.post(url, data, { withCredentials: true }).then(res => {
      updateProfile({ name, email, phone, city, })
    })

    onClose()
  }

  // Delete from basket
  const handleBasketDelete = (itemID, productID) => {
    if (isLoggedIn) {
      const data = new FormData()
      data.append("basketItemID", itemID)

      axios
        .post(`/basket/item/delete/`, data, {
          withCredentials: true
        })
        .then(res => {
          console.log("Basket::Delete: ", res.data)
          deleteFromBasket(productID)
        })
        .catch(err => {
          console.log("Basket::DeleteError ", err)
        })
    } else {
      deleteFromBasket(productID)
    }
  }

  const handleDeliveryChanges = (city, department) => {
    setCity(city)
    setDepartment(department)
  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth
      scroll="paper"
      className="ActionsDialog"
      maxWidth="md"
    >
      {!props.section ? (
        <DialogTitle>
          {section === SECTION_DEFAULT && "Меню"}
          {section === SECTION_ORDERS_HISTORY &&
            (translation.language === "ua"
              ? "Історія покупок"
              : "История покупок")}
          {section === SECTION_PROFILE &&
            (translation.language === "ua" ? "Профіль" : "Профиль")}
          {section === SECTION_BASKET &&
            (translation.language === "ua" ? "Кошик" : "Корзина")}
          {section === SECTION_LOG_OUT &&
            (translation.language === "ua" ? "Вихід" : "Выход")}

          {section !== SECTION_DEFAULT && (
            <IconButton onClick={() => setSection(SECTION_DEFAULT)}>
              <ChevronLeft />
            </IconButton>
          )}
        </DialogTitle>
      ) : (
        <DialogTitle>
          {translation.language === "ua" ? "Кошик" : "Корзина"}
        </DialogTitle>
      )}
      <DialogContent>
        {section === SECTION_DEFAULT && (
          <div className="ActionsDialog--group">
            <div className="ActionsDialog--row">
              <Typography variant="h6">
                {translation.language === "ua" && "Кошик"}
                {translation.language === "ru" && "Корзина"}
              </Typography>
              <IconButton
                onClick={() => setSection(SECTION_BASKET)}
                color="primary"
              >
                <ChevronRight />
              </IconButton>
            </div>
            <div className="ActionsDialog--row">
              <Typography variant="h6">
                {translation.language === "ua" && "Історія покупок"}
                {translation.language === "ru" && "История покупок"}
              </Typography>
              <IconButton
                color="primary"
                onClick={() => setSection(SECTION_ORDERS_HISTORY)}
              >
                <ChevronRight />
              </IconButton>
            </div>
            <div className="ActionsDialog--row">
              <Typography variant="h6">
                {translation.language === "ua" && "Профіль"}
                {translation.language === "ru" && "Профиль"}
              </Typography>
              <IconButton
                onClick={() => setSection(SECTION_PROFILE)}
                color="primary"
              >
                <ChevronRight />
              </IconButton>
            </div>
            <div className="ActionsDialog--row">
              <Typography variant="h6">
                {translation.language === "ua" && "Вийти"}
                {translation.language === "ru" && "Выйти"}
              </Typography>
              <IconButton
                color="primary"
                onClick={() => setSection(SECTION_LOG_OUT)}
              >
                <ChevronRight />
              </IconButton>
            </div>
          </div>
        )}
        {section === SECTION_BASKET && (
          <div className="ActionsDialog--group">
            {sessionBasket.length === 0 && (
              <Typography className="no--orders" variant="h6">
                {translation.language === "ua" && "Ваш кошик пустий"}
                {translation.language === "ru" && "Ваша корзина пуста "}
              </Typography>
            )}
            <div className="ActionsDialog--order">
              {sessionBasket.map(item => {
                return (
                  <div
                    className="ActionsDialog--order--item"
                    key={item.product.id}
                  >
                    <div
                      className="ActionsDialog--order--image"
                      style={{
                        background: `url('${process.env.REACT_APP_API}/media/${item.product.picture.split(",")[0]
                          }')`
                      }}
                    ></div>
                    <div className="ActionsDialog--item--info">
                      <Typography variant="button" className="ActionsDialog--item--name">
                        {translation.translateKey(item.product, "name")} -{" "}
                        {item.info.quantity} штук
                      </Typography>
                      <Typography variant="button">
                        {translation.language === "ua" && "Разом: "}
                        {translation.language === "ru" && "Вместе: "}
                        {(
                          item.info.quantity * (item.product.with_action ? item.product.new_price : item.info.product_price)
                        ).toLocaleString()}
                      </Typography>
                    </div>
                    <IconButton
                      onClick={() =>
                        handleBasketDelete(item.info.id, item.product.id)
                      }
                    >
                      <Delete />
                    </IconButton>
                  </div>
                )
              })}
            </div>
            <div className="ActionsDialog--continue">
              <Link to="/basket">
                <Button variant="contained" color="primary" disableElevation>
                  {translation.language === "ua" ? "Продовжити" : "Продолжить"}
                </Button>
              </Link>
            </div>
          </div>
        )}
        {section === SECTION_PROFILE && (
          <div className="ActionsDialog--group">
            <TextField
              label={
                translation.language === "ua"
                  ? "Ім'я"
                  : "Имя" + sessionUser.name
              }
              defaultValue={sessionUser.name}
              margin="dense"
              fullWidth
              onChange={e => setName(e.target.value)}
            />
            <TextField
              label="Email"
              defaultValue={sessionUser.email}
              margin="dense"
              fullWidth
              onChange={e => setEmail(e.target.value)}
            />
            <TextField
              label="Телефон"
              defaultValue={sessionUser.phone}
              margin="dense"
              fullWidth
              onChange={e => setPhone(e.target.value)}
            />
            <DeliveryDetailsForm onChange={handleDeliveryChanges} sessionUser={sessionUser} />
            <InputLabel id="demo-simple-select-label">{translation.language === "ua"
              ? "Спосіб оплати"
              : "Способ оплаты"
            }</InputLabel>
            <Select
              style={{ width: "100%" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={payment}
              onChange={e => setPayment(parseInt(e.target.value, 10))}
            >
              <MenuItem value={1}>{translation.language === "ua"
                ? "Оплата на банківський рахунок"
                : "Оплата на банковский счет"
              }</MenuItem>
              {/* <MenuItem value={2}>{translation.language === "ua"
                ? "Оплата при отриманні товару"
                : "Оплата при получении товара"
              }</MenuItem> */}
            </Select>

            <TextField
              label="Новый пароль"
              margin="dense"
              fullWidth
              type="password"
            />

            <Button variant="contained" color="primary" onClick={handleUpdate} disableElevation>
              {translation.language === "ua" ? "Зберегти" : "Сохранить"}
            </Button>
          </div>
        )}
        {section === SECTION_ORDERS_HISTORY && (
          <div className="ActionsDialog--group">
            {sessionOrders.length === 0 && (
              <Typography className="no--orders" variant="h6">
                {translation.language === "ua" && "У вас немає покупок"}
                {translation.language === "ru" && "У вас еще нету покупок"}
              </Typography>
            )}
            {sessionOrders.map(order => {
              return (
                <div className="ActionsDialog--order" key={order.info.id}>
                  <div className="ActionsDialog--order--title">
                    <Typography variant="button">
                      {translation.language === "ua" && "Замовлення "}
                      {translation.language === "ru" && "Заказ "}
                      {new Date(order.info.made_at * 1000).toLocaleDateString()}
                    </Typography>
                    <Typography variant="button">
                      {statusString(
                        order.info.order_status,
                        translation.language
                      )}
                    </Typography>
                  </div>
                  {order.items.map(item => {
                    return (
                      <div
                        className="ActionsDialog--order--item"
                        key={item.product.id}
                      >
                        <div
                          className="ActionsDialog--order--image"
                          style={{
                            background: `url('${process.env.REACT_APP_API
                              }/media/${item.product.picture.split(",")[0]}')`
                          }}
                        ></div>
                        <div className="ActionsDialog--item--info">
                          <Typography variant="button">
                            {translation.translateKey(item.product, "name")} -{" "}
                            {item.info.quantity} штук
                          </Typography>
                          <Typography variant="button">
                            {translation.language === "ua" && "Разом: "}
                            {translation.language === "ru" && "Вместе: "}
                            {(
                              item.info.quantity * item.info.product_price
                            ).toLocaleString()}{" "}
                            ГРН
                          </Typography>
                        </div>
                      </div>
                    )
                  })}
                  <Typography
                    variant="h6"
                    className="ActionsDialog--total--price"
                  >
                    {translation.language === "ua" && "Загальна сума: "}
                    {translation.language === "ru" && "Общая сума: "}
                    {order.info.total_price.toLocaleString()} ГРН
                  </Typography>
                </div>
              )
            })}
          </div>
        )}
        {section === SECTION_LOG_OUT && (
          <>
            <DialogContentText>
              {translation.language === "ua" && "Ви дійсно хочете вийти?"}
              {translation.language === "ru" &&
                "Вы действительно хотите выйти?"}
            </DialogContentText>
          </>
        )}
      </DialogContent>
      {
        section === SECTION_LOG_OUT && (
          <DialogActions>
            <Button variant="text" onClick={onClose}>
              {translation.language === "ua" && "Відміна"}
              {translation.language === "ru" && "Отмена"}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                onLogOut()
                onClose()
              }}
              disableElevation
            >
              {translation.language === "ua" && "Вийти"}
              {translation.language === "ru" && "Выйти"}
            </Button>
          </DialogActions>
        )
      }
    </Dialog >
  )
}

const mapDispatchToProps = dispatch => {
  return {
    updateProfile: profile =>
      dispatch({ type: actions.UPDATE_PROFILE, payload: profile }),
  }
}

export default connect(null, mapDispatchToProps)(ActionsDialog)
