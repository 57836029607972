import React from "react";
import { connect } from "react-redux";

import Header from "./../ui/Header";
import Container from "./../ui/Container";
import PageTitle from "./../ui/PageTitle";
import { Typography } from "@material-ui/core";
import DaburImage from "./AboutUsImages/dabur.jpg";
import AromaImage from "./AboutUsImages/aroma.jpg";
import FreemanImage from "./AboutUsImages/freeman.jpg";
import BebecoImage from "./AboutUsImages/bebeco.jpg";
import OrihiroImage from "./AboutUsImages/orihiro.jpg";
import FeatherImage from "./AboutUsImages/Feather.jpg";
import PatanjaliImage from "./AboutUsImages/patanjali.jpg"
import "./AboutUs.css";

const AboutUs = ({ translation, isHomeScreen }) => {
  return (
    <div className="AboutUs">
      <Header />
      <PageTitle
        title={translation.language === "ua" ? "Про нас" : "О нас"}
        homeScreen={isHomeScreen ? true : false}
      />
      <Container>
        <div className="AboutUs--content">
          <div className="AboutUs--row">
            <div className="AboutUs--title">
              <Typography variant="h5">
                {translation.language === "ua" && "Компанія"}
                {translation.language === "ru" && "Компания"}
              </Typography>
            </div>
            {translation.language === "ua" && (
              <Typography variant="body1">
                Компанія «ЯВА Інтернешенл», заснована в 2005 році, займається
                оптовою торгівлею парфумерії та косметики. Компанія є
                ексклюзивним представником в Україні ряду відомих світових
                виробників, чия продукція вже давно встигла зарекомендувати себе
                в багатьох країнах світу як якісна, витончена і стильна, але
                доступна за ціною кожному покупцеві. Серед них: FREEMAN® (США),
                DABUR INTERNATIONAL® (Індія), BEBECO® (Корея), AROMA PERFUME®
                (Україна), FAMILLE GRASSE PARFUMS (Франція), ORIHIRO (Японія),
                FEATHER (Японія).
              </Typography>
            )}
            {translation.language === "ru" && (
              <Typography variant="body1">
                Компания «ЯВА Интернешенл», основанная в 2005 году, занимается
                оптовой торговлей парфюмерии и косметики. Компания является
                эксклюзивным представителем в Украине ряда известных мировых
                производителей, чья продукция уже давно успела зарекомендовать
                себя во многих странах мира как качественная, изящная и
                стильная, но доступная по цене каждому покупателю. Среди них:
                FREEMAN® (США), DABUR INTERNATIONAL® (Индия) BEBECO® (Корея)
                AROMA PERFUME® (Украина), FAMILLE GRASSE PARFUMS (Франция),
                ORIHIRO (Япония), FEATHER (Япония).
              </Typography>
            )}
          </div>
          <div className="AboutUs--row">
            <div className="AboutUs--title">
              <Typography variant="h5">
                {translation.language === "ua" && "Продукція"}
                {translation.language === "ru" && "Продукция"}
              </Typography>
            </div>
            {translation.language === "ua" && (
              <Typography variant="body1">
                З нашою продукцією вже знайомі в усіх регіонах України (мережі
                магазинів Eva, Космо, ProStor, Шик і Блиск, Мегамаркет, NOVUS,
                Епіцентр, Архітектор Краси, Solo, мережі аптек Зростання, TAS,
                Ваше здоров&#39;я, інтернет магазини Parfums.ua, MakeUp, Modna
                Kasta, і багато інших). З питань співпраці заповніть форму
                зворотного зв&#39;язку на сайті, або зателефонуйте за телефонами
                0372 54 38 90; 067 37 24 364; 050 37 86 664 в робочий час з
                понеділка по п&#39;ятницю з 08.30 по 17.20.
              </Typography>
            )}
            {translation.language === "ru" && (
              <Typography variant="body1">
                С нашей продукцией уже знакомы во всех регионах Украины (сети
                магазинов Eva, Космо, ProStor, Шик и Блеск, Мегамаркет, NOVUS,
                Епицентр, Архитектор Красоты, Solo, сети аптек Рост, TAS, Ваше
                здоров&#39;я, интернет магазины Parfums.ua, MakeUp, Modna Kasta,
                и многие другие). По вопросам сотрудничества заполните форму
                обратной связи на сайте, или позвоните по телефонам 0372 54 38
                90; 067 37 24 364; 050 37 86 664 в рабочее время с понедельника
                по п&#39;ятницу с 08.30 по 17.20.
              </Typography>
            )}
          </div>
          <div className="AboutUs--row">
            <div className="AboutUs--title">
              <Typography variant="h5">
                {translation.language === "ua" && "Інтернет-магазин"}
                {translation.language === "ru" && "Интернет-магазин"}
              </Typography>
            </div>
            {translation.language === "ua" && (
              <Typography variant="body1">
                У нашому інтернет-магазині доступний широкий вибір товарів для
                догляду за обличчям, тілом та волоссям за найдоступнішими
                цінами. Асортимент постійно оновлюється, при цьому щомісяця
                проходять акції і розпродажі різної продукції. Наші менеджери
                готові надати будь-яку інформацію про цікавить вас товар.
              </Typography>
            )}
            {translation.language === "ru" && (
              <Typography variant="body1">
                В нашем интернет-магазине доступен широкий выбор товаров по
                уходу за лицом, телом и волосами по самым доступным ценам.
                Ассортимент постоянно обновляется, при этом ежемесячно проходят
                акции и распродажи различной продукции. Наши менеджеры готовы
                предоставить любую информацию об интересующем вас товаре.
              </Typography>
            )}
          </div>
          <div className="AboutUs--row">
            <div className="AboutUs--title">
              <Typography variant="h5">
                {translation.language === "ua" && "Наші бренди"}
                {translation.language === "ru" && "Наши бренды"}
              </Typography>
            </div>
            {translation.language === "ua" && (
              <Typography variant="body1">
                Компанія FREEMAN заснована в 1915 році в США, Беверлі Хілз
                сім&#39;єю Фрімен. Спочатку корпорація займалася розробкою і
                випуском виключно професійної косметики на замовлення
                голлівудських кіностудій і салонів краси. Але третє покоління
                родини Фріман вийшло з косметичною продукцією на широкий ринок.
                Перевагою даної косметики також є те, що в її основі в якості
                активного компонента містяться речовини рослинного походження.
                Препарати компанії розробляються у власній Лабораторії Краси, в
                якій щорічно проводяться широкомасштабні дослідження.
                Косметика від Freeman не містить парабенів, сульфатів, фталатів,
                мінерального масла, а також синтетичних консервантів і
                барвників. Косметика є &quot;cruelty free&quot; і не тестується
                на тваринах.
              </Typography>
            )}
            {translation.language === "ru" && (
              <Typography variant="body1">
                Компания FREEMAN основана в 1915 году в США, Беверли Хилз семьей
                Фримен. Первоначально корпорация занималась разработкой и
                выпуском исключительно профессиональной косметики по заказу
                голливудских киностудий и салонов красоты. Но третье поколение
                семьи Фриман вышло с косметической продукцией на широкий рынок.
                Преимуществом  данной косметики также является то, что в ее
                основе в качестве активного компонента содержатся вещества
                растительного происхождения. Препараты компании разрабатываются
                в собственной Лаборатории Красоты, в которой ежегодно проводятся
                широкомасштабные исследования. Косметика от Freeman не содержит
                парабенов, сульфатов, фталатов, минерального масла, а также
                синтетических консервантов и красителей. Косметика является
                “cruelty free” и не тестируется на животных.
              </Typography>
            )}
          </div>
          <div className="AboutUs--row">
            <img alt="freeman" src={FreemanImage} />
            {translation.language === "ua" && (
              <Typography variant="body1">
                Компанія DABUR INTERNATIONAL заснована в 1884 році. Це провідний
                виробник аюрведичні препаратів і засобів по догляду за
                здоров&#39;ям, включаючи лікарські препарати, біологічно
                активних добавок, косметичних товарів і продуктів харчування в
                світі. Компанія володіє дванадцятьма заводами не тільки в Індії,
                але і за кордоном.   Фахівці компанії зуміли поєднати не тільки
                цілий ряд екстрактів натуральних рослин, але і зберегти все
                багатства індійської природи. Науково-дослідна робота є основним
                компонентом успіху діяльності DABUR.   Сьогодні DABUR має всі
                можливості виробляти якісні продукти і намагається постійно
                покращувати їх якість. Високотехнологічні лабораторії,
                висококваліфіковані вчені гарантують, що кожен препарат,
                виходячи на ринок, має найвищий стандарт якості, володіє
                максимальною безпекою та еффективністю.
              </Typography>
            )}
            {translation.language === "ru" && (
              <Typography variant="body1">
                Компания DABUR INTERNATIONAL основана в 1884 году. Это ведущий
                производитель аюрведических препаратов и средств по уходу за
                здоровьем, включая лекарственные препараты, биологически
                активных  добавок, косметических товаров и продуктов питания в
                мире. Компания владеет двенадцатью заводами не только в Индии,
                но и за рубежом.   Специалисты компании сумели совместить не
                только целый ряд экстрактов натуральных растений, но и сохранить
                все богатства индийской природы. Научно-исследовательская работа
                является основным компонентом успеха деятельности DABUR.
                Сегодня DABUR имеет все возможности производить качественные
                продукты и старается постоянно улучшать их качество.
                Высокотехнологичные лаборатории, высококвалифицированные ученые
                гарантируют, что каждый препарат, выходя на рынок, имеет самый
                высокий стандарт качества, обладает максимальной безопасностью.
              </Typography>
            )}
          </div>
          <div className="AboutUs--row">
            <img alt="dabur" src={DaburImage} />
            {translation.language === "ua" && (
              <Typography variant="body1">
                Компанія ТОВ «Арома Парфюм» є лідером з виробництва парфумерії
                на ринку України.   Продукція компанії «Арома Парфюм» успішно
                реалізується на всій території України (на даний момент має
                понад 70 постійних партнерів), а також в країнах ближнього і
                далекого зарубіжжя, таких як Молдова, Болгарія,
                Латвія, Саудівська Аравія і Кувейт.   Компоненти, що
                використовуються при створенні парфумерних композицій,
                закуповуються у кращих європейських виробників. Продукція
                компанії пройшла сертифікацію не тільки в країнах СНД, але і в
                Європі.
              </Typography>
            )}
            {translation.language === "ru" && (
              <Typography variant="body1">
                Продукция компании «Арома Парфюм» успешно реализуется на всей
                территории Украины (на данный момент имеет более 70 постоянных
                партнеров), а также в странах ближнего и дальнего зарубежья,
                таких как Белоруссия, Россия, Молдова, Болгария, Латвия,
                Саудовская Аравия и Кувейт.   Компоненты, используемые при
                создании парфюмерных композиций, закупаются у лучших европейских
                производителей. Продукция компании прошла сертификацию не только
                в странах СНГ, но и в Европе.
              </Typography>
            )}
          </div>
          <div className="AboutUs--row">
            <img alt="aroma" src={AromaImage} />
            {translation.language === "ua" && (
              <Typography variant="body1">
                Компанія BEBECO вдало поєднує східні традиції з прогресивними
                технологіями. Як результат - косметика, аналогів якої в Європі
                немає.   Історія компанії BEBECO почалася в 1994 р зі створення
                ідеї, і вже в 2002 році була заснована власна фабрика. За
                порівняно недовгий термін вона стала однією з найпопулярніших і
                в Кореї і далеко за її межами. BEBECO випускає свою продукцію,
                зроблену на основі натуральних інгредієнтів. Дуже багатьох
                покупців приваблює не тільки помірна вартість косметики BEBECO,
                а й застосування традиційної рецептури, особливих колірних
                напрямів і спецефектів.
              </Typography>
            )}
            {translation.language === "ru" && (
              <Typography variant="body1">
                Компания BEBECO удачно сочетает восточные традиции с
                прогрессивными технологиями. Как результат – косметика, аналогов
                которой в Европе нет.   История компании BEBECO началась в 1994
                г. с создания идеи, и уже в 2002 году была основана собственная
                фабрика. За сравнительно недолгий срок она стала одной из самых
                популярных и в Корее и далеко за её пределами. BEBECO выпускает
                свою продукцию, сделанную на основе натуральных ингредиентов.
                Очень многих покупателей привлекает не только умеренная
                стоимость косметики BEBECO, но и применение традиционной
                рецептуры, особых цветовых направлений и спецэффектов.
              </Typography>
            )}
            <img alt="bebeco" src={BebecoImage} />
          </div>
          <div className="AboutUs--row">
            {translation.language === "ua" && (
              <Typography variant="body1">
                Компанія Orihiro заснована в 1972 році в м. Томіока (Японія).
                Свою діяльність компанія розпочала з виробництва
                високотехнологічних машин для харчової промисловості. В 1977
                році розпочато напрямок здорового харчування. Основною базою
                розвитку групи Orihiro є Науково-дослідний інститут Orihiro
                Plantdew з головним офісом в м. Такасакі (Японія). Корпоративна
                політика компанії – це внесок в розвиток суспільства і постійне
                підвищення рівня довіри клієнтів. Кожний продукт ORIHIRO - це не
                просто комплекс вітамінів, мінералів та інших корисних речовин,
                це результат серйозних наукових досліджень, тривалих
                експериментів та унікальних технологій виробництва. Головним
                напрямком політики компанії є використання лише екологічно
                безпечного сировинного та технологічного виробництва.
              </Typography>
            )}
            {translation.language === "ru" && (
              <Typography variant="body1">
                Компания Orihiro основана в 1972 году в. Томиока (Япония). Свою
                деятельность компания начала с производства высокотехнологичных
                машин для пищевой промышленности. В 1977 году начато направление
                здорового питания. Основной базой развития группы Orihiro
                является Научно-исследовательский институт Orihiro Plantdew с
                главным офисом в г.. Такасаки (Япония). Корпоративная политика
                компании - это вклад в развитие общества и постоянное повышение
                уровня доверия клиентов. Каждый продукт ORIHIRO - это не просто
                комплекс витаминов, минералов и других полезных веществ, это
                результат серьезных научных исследований, длительных
                экспериментов и уникальных технологий производства. Главным
                направлением политики компании является использование только
                экологически безопасного сырьевого и технологического
                производства.
              </Typography>
            )}
            <img alt="orihiro" src={OrihiroImage} />
          </div>
          <div className="AboutUs--row">
            {translation.language === "ua" && (
              <Typography variant="body1">
                Японська компанія Feather була заснована в 1932 році і є
                світовим лідером виробництва не тільки товарів для гоління, а
                також хірургічних та професійних інструментів для перукарень.
                Продукція Feather експортується в більш ніж 120 країн світу.
                Компанія володіє технологіями по надточній обробці металів. Леза
                Feather офіційно визнані самими гострими в світі. Більш того їх
                звели в категорію класу люкс. Компанія Feather зарекомендувала
                себе на ринку завдяки вигідній ціні при справжній японській
                якості.
              </Typography>
            )}
            {translation.language === "ru" && (
              <Typography variant="body1">
                Японская компания Feather была основана в 1932 году и является
                мировым лидером производства не только товаров для бритья, а
                также хирургических и профессиональных инструментов для
                парикмахерских. Продукция Feather экспортируется в более чем 120
                стран мира. Компания владеет технологиями по сверхточной
                обработке металлов. Лезвия Feather официально признаны самыми
                острыми в мире. Более того их возвели в категорию класса люкс.
                Компания Feather зарекомендовала себя на рынке благодаря
                выгодной цене при настоящий японской качества.
              </Typography>
            )}
            <img alt="feather" src={FeatherImage} />
          </div>

          <div className="AboutUs--row">
            {translation.language === "ua" && (
              <Typography variant="body1">
                Patanjali - це індійська компанія, яка була заснована у 2006
                році у Гургаоні. Вона присвячена ідеології Аюрведи - давньої
                індійської медичної системи, яка вже століттями відома своїми
                корисними знаннями та надійністю. За короткий час Patanjali
                стала однією з найшвидше зростаючих компаній в Індії, завоювавши
                лояльність та повагу від споживачів. Головним принципом компанії
                є використання натуральних інгредієнтів у виготовленні своїх
                продуктів, що відомі своїми лікувальними властивостями та
                корисним впливом на організм людини.
              </Typography>
            )}
            {translation.language === "ru" && (
              <Typography variant="body1">
                Patanjali – это индийская компания, которая была основана в 2006
                году в Гургаоне. Она посвящена идеологии Аюрведы – древней
                индийской медицинской системы, которая уже столетиями известна
                своими полезными знаниями и надежностью. За короткое время
                Patanjali стала одной из самых быстрорастущих компаний в Индии,
                завоевав лояльность и уважение потребителей. Главным принципом
                компании является использование натуральных ингредиентов в
                изготовлении своих продуктов, известных своими лечебными
                свойствами и полезным воздействием на организм человека.
              </Typography>
            )}
            <img alt="feather" src={PatanjaliImage} />
          </div>
        </div>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    translation: state.translationReducer,
  };
};

export default connect(mapStateToProps)(AboutUs);
