import React, { useEffect, useState } from "react"

import { connect } from "react-redux"
import * as actions from "./../../store/actions"

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  Button,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox
} from "@material-ui/core"

import axios from "axios"
import SearchCityAutocomplete from "../../ui/SearchCityAutocomplete"
import SearchDepartmentAutocomplete from "../../ui/SearchDepartmentAutocomplete"

const ConfirmDialog = (props) => {
  const {
    handleConfirmationError,
    handleBarMsg,
    sessionBasket,
    translation,
    handleClose,
    clearBasket,
    open,
    isLoggedIn,
    sessionUser,
    updateProfile,
  } = props

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [payment, setPayment] = useState(2)
  const [agreement, setAgreement] = useState({
    privacy: false,
    publicOffer: false
  })

  const [city, setCity] = useState(sessionUser.city)
  const [department, setDepartment] = useState(sessionUser.department)

  const labelPrivacy = (
    <span>
      Погоджуюсь із{" "}
      <a href="/privacyPolicy" target="_blank" rel="noopener noreferrer">
        політикою конфіденційності
      </a>
    </span>
  );

  const labelPublicOffer = (
    <span>
      Погоджуюсь із{" "}
      <a href="/publicOfferAgreement" target="_blank" rel="noopener noreferrer">
        договором публічної оферти
      </a>
    </span>
  );



  useEffect(() => {
    setCity(sessionUser.city)
    setDepartment(sessionUser.department)
  }, [sessionUser.department, sessionUser.city])

  const validateEmail = email => {
    /* eslint-disable no-useless-escape */
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  const isValid = () => {
    if (isLoggedIn) {
      if (department && city) {
        return department.id !== "" && department.id !== ""
      }

      return false
    }

    if (!agreement.privacy || !agreement.publicOffer) {
      return false
    }

    if ((phone !== "" && name !== "") || email !== "") {
      return ((
        phone.length <= 50 && phone.length >= 1
        && name.length <= 50 && name.length >= 1)
        || validateEmail(email)
      )
    }
  }

  const nameLabel = translation.language === "ua" ? "Ваше ім'я і прізвище" : "Ваше имя и фамилия"
  const phoneLabel = "Ваш телефон"
  const emailLabel = () => {
    if (email.length === 0) {
      return "Email"
    } else {
      if (!validateEmail(email)) {
        return translation.language === "ua" ? "Хибний email" : "Неверный email"
      } else {
        return "Email"
      }
    }
  }

  const handleBuyConfirm = () => {
    const data = new FormData()

    data.append("name", name)
    data.append("phone", phone)
    data.append("email", email)
    data.append("payment", payment)
    data.append("city", city.id)
    data.append("department", department.id)

    const items = []
    sessionBasket.forEach(basketItem => {
      items.push({
        basket_item_id: basketItem.info.id,
        product_id: basketItem.product.id,
        quantity: basketItem.info.quantity
      })
    })

    data.append("order", JSON.stringify(items))

    axios
      .post("order/make/", data, { withCredentials: true })
      .then(res => {
        console.log("[Order]:")
        handleConfirmationError(false)
        clearBasket()
      })
      .catch(err => {
        console.log("[Order::Error]:", err)
        handleConfirmationError(true)
      })
      .finally(() => {
        handleBarMsg(true)
        handleClose()
      })

    updateProfile({
      name, phone, email, payment,
    })
  }

  const getAdditionalFields = () => {
    return <div>
      <InputLabel
        id="demo-simple-select-label"
        style={{ marginTop: 20 }}
      >{translation.language === "ua"
        ? "Спосіб оплати"
        : "Способ оплаты"
        }</InputLabel>
      <Select
        style={{ width: "100%" }}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={payment}
        onChange={e => setPayment(parseInt(e.target.value, 10))}
      >
        <MenuItem value={1}>
          {translation.language === "ua"
            ? "Оплата на банківський рахунок"
            : "Оплата на банковский счет"
          }</MenuItem>
        {/* <MenuItem value={2}>
          {translation.language === "ua"
            ? "Оплата при отриманні товару"
            : "Оплата при получении товара"
          }</MenuItem> */}


      </Select>

      <SearchCityAutocomplete onChange={city => setCity(city)} />
      {city && <SearchDepartmentAutocomplete onChange={department => setDepartment(department)} city={city.id} />}
    </div>
  }

  const getTextForLoggedInUser = () => {
    if (sessionUser.city === "" || sessionUser.department === "") {
      return getAdditionalFields()
    } else {
      return (
        <>
          <Typography variant="body1">
            {translation.language === "ua" &&
              "Ви зможете стежити за статусом вашого замовлення в історії замовлень"}
            {translation.language === "ru" &&
              "Вы сможете следить за статусом вашего заказа в істории заказов"}
          </Typography>
        </>
      )
    }
  }


  return (
    <Dialog fullWidth onClose={handleClose} open={open}>
      <DialogTitle>
        {translation.language === "ua" && "Підтвердіть покупку"}
        {translation.language === "ru" && "Подтвердите покупку"}
      </DialogTitle>
      <DialogContent>
        {isLoggedIn ? getTextForLoggedInUser() : (
          <>
            <DialogContentText>
              {translation.language === "ua" &&
                "Ви повинні залишити ваші контактні дані для продовження покупки або зареєструватися на сайті."}
              {translation.language === "ru" &&
                "Вы должны оставить ваши контактные даные на продолжения покупки или зарегистрироваться на сайте."}
            </DialogContentText>
            <TextField
              fullWidth
              onChange={e => setName(e.target.value)}
              margin="dense"
              label={nameLabel}
              required
              helperText={translation.language === "ua" ? "Обов'язкове поле" : "Об'язательное поле"}
            ></TextField>
            <TextField
              onChange={e => setEmail(e.target.value)}
              type="email"
              fullWidth
              margin="dense"
              label={emailLabel()}
              helperText={translation.language === "ua" ? "Не обов'язкове поле" : "Не об'язательное поле"}
            ></TextField>
            <TextField
              onChange={e => setPhone(e.target.value)}
              fullWidth
              type="tel"
              margin="dense"
              required
              label={phoneLabel}
              helperText={translation.language === "ua" ? "Обов'язкове поле" : "Об'язательное поле"}
            >
            </TextField>
            {getAdditionalFields()}
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={agreement.privacy}
                    onChange={(event) => setAgreement({ ...agreement, privacy: event.target.checked })}
                    name="privacy"
                  />
                }
                label={labelPrivacy}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={agreement.publicOffer}
                    onChange={(event) => setAgreement({ ...agreement, publicOffer: event.target.checked })}
                    name="publicOffer"
                  />
                }
                label={labelPublicOffer}
              />
            </FormGroup>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" onClick={handleClose}>
          {translation.language === "ua" && "Відмінити"}
          {translation.language === "ru" && "Отменить"}
        </Button>
        <Button
          variant="contained"
          disabled={!isValid()}
          disableElevation
          color="primary"
          onClick={handleBuyConfirm}
        >
          {translation.language === "ua" && "Підтвердити"}
          {translation.language === "ru" && "Подтвердить"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    deleteFromBasket: itemID =>
      dispatch({ type: actions.DELETE_FROM_BASKET, payload: itemID }),
    clearBasket: () => dispatch({ type: actions.CLEAR_BASKET }),
    updateBasketItemQuantity: (itemID, quantity) => {
      dispatch({
        type: actions.UPDATE_BASKET_ITEM_QUANTITY,
        payload: {
          itemID: itemID,
          quantity: quantity
        }
      })
    },
    updateProfile: profile =>
      dispatch({ type: actions.UPDATE_PROFILE, payload: profile }),
  }
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.sessionReducer.isLoggedIn,
    sessionUser: state.sessionReducer.user,
    translation: state.translationReducer,
    sessionBasket: state.sessionReducer.basket
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDialog)