import React, { useState } from "react"

import { connect } from "react-redux"
import * as actions from "./../../store/actions"
import { Snackbar } from "@material-ui/core"

import axios from "axios"

import {
  Button,
  Typography,
} from "@material-ui/core"

import Container from "./../../ui/Container"
import Header from "./../../ui/Header"

import Item from "./Item"
import "./Basket.scss"
import ConfirmDialog from "./ConfirmDialog"

const Basket = props => {
  const {
    sessionBasket,
    isLoggedIn,
    deleteFromBasket,
    translation,
    updateBasketItemQuantity,
    user,
  } = props

  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [confirmationError, setConfirmationError] = useState(false)

  const handleItemDelete = (itemID, productID) => {
    if (isLoggedIn) {
      const data = new FormData()
      data.append("basketItemID", itemID)

      axios
        .post(`/basket/item/delete/`, data, {
          withCredentials: true
        })
        .then(res => {
          deleteFromBasket(productID)
        })
        .catch(err => { })
    } else {
      deleteFromBasket(productID)
    }
  }

  const handleItemQuantity = (itemID, productID, quantity) => {
    if (isLoggedIn) {
      const data = new FormData()

      data.append("basketItemID", itemID)
      data.append("quantity", quantity)

      axios
        .post(`/basket/item/quantity/`, data, {
          withCredentials: true
        })
        .then(res => {
          updateBasketItemQuantity(productID, quantity)
        })
    } else {
      updateBasketItemQuantity(productID, quantity)
    }
  }

  const snackBarLabel = () => {
    if (isLoggedIn) {
      if (user.email !== "") {
        if (translation.language === "ua") {
          return "Дякуємо за покупку! Деталі замовлення надіслані вам на пошту"
        } else {
          return "Спасибо! Детали заказа отправлены вам на почту"
        }
      } else {
        if (translation.language === "ua") {
          return "Дякуємо за покупку! Ваше замовлення прийнято"
        } else {
          return "Спасибо, ваш заказ принят"
        }
      }
    } else if (confirmationError) {
      if (translation.language === "ua") {
        return "Сталась помилка при перевірці введеної інформації, спробуйте ще раз"
      } else {
        return "Произошла ошибка при проверке введенной информации, попробуйте еще раз"
      }
    } else {
      if (translation.language === "ua") {
        return "Дякуємо за покупку! Найближчим часом з вами зв'яжуться! "
      } else {
        return "Спасибо! В ближайшее время с вами свяжутся!"
      }
    }
  }


  return (
    <div className="Basket">
      <Header />
      <Container>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={snackBarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackBarOpen(false)}
          message={snackBarLabel()}
        />
        {sessionBasket.length !== 0 && (
          <div className="titles">
            <p>Продукт</p>
            <p className="product">Ціна</p>
            <p>Кількість</p>
            <p>Разом</p>
          </div>
        )}

        {sessionBasket.length === 0 && (
          <div className="Basket--empty">
            <Typography variant="h5">
              {translation.language === "ua" && "Ваш кошик пустий"}
              {translation.language === "ru" && "Ваша корзина пуста"}
            </Typography>
          </div>
        )}

        <div className="items">
          {sessionBasket.map(item => (
            <Item
              key={item.info.id}
              {...item}
              translation={props.translation}
              onDelete={() => handleItemDelete(item.info.id, item.product.id)}
              onQuantityUpdate={handleItemQuantity}
            />
          ))}
        </div>

        {sessionBasket.length !== 0 && (
          <div className="purchase__button">
            <Button
              onClick={() => setConfirmDialogOpen(true)}
              variant="contained"
              color="primary"
              disableElevation
            >
              {translation.language === "ua" && "Придбати"}
              {translation.language === "ru" && "Купить"}
            </Button>
          </div>
        )}
      </Container>

      <ConfirmDialog open={confirmDialogOpen} handleConfirmationError={setConfirmationError} handleBarMsg={setSnackBarOpen} handleClose={e => setConfirmDialogOpen(false)} />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.sessionReducer.isLoggedIn,
    user: state.sessionReducer.user,
    translation: state.translationReducer,
    sessionBasket: state.sessionReducer.basket
  }
}

const mapDispatchToProps = dispatch => {
  return {
    deleteFromBasket: itemID =>
      dispatch({ type: actions.DELETE_FROM_BASKET, payload: itemID }),
    clearBasket: () => dispatch({ type: actions.CLEAR_BASKET }),
    updateBasketItemQuantity: (itemID, quantity) => {
      dispatch({
        type: actions.UPDATE_BASKET_ITEM_QUANTITY,
        payload: {
          itemID: itemID,
          quantity: quantity
        }
      })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Basket)
