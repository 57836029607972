export const APPLY_SESSION = "APPLY_SESSION"
export const LOG_OUT = "LOG_OUT"
export const SET_LANGUAGE = "SET_LANGUAGE"
export const STORE_BASKET = "STORE_BASKET"
export const DELETE_FROM_BASKET = "DELETE_FROM_BASKET"
export const UPDATE_BASKET_ITEM_QUANTITY = "UPDATE_BASKET_ITEM_QUANTITY"
export const CONFIRM_BUY_SUCCEED = "CONFIRM_BUY_SUCCEED"
export const CLEAR_BASKET = "CLEAR_BASKET"

// PROFILE
export const UPDATE_PROFILE = "UPDATE_PROFILE"