import React, { useEffect } from "react"
import { Switch, Route } from "react-router-dom"
import { connect } from "react-redux"
import * as actions from "./store/actions"
import axios from "axios"
import { createTheme } from "@material-ui/core/styles"
import { ThemeProvider } from "@material-ui/styles"
import { teal } from "@material-ui/core/colors"
import ReactGA from "react-ga"

import Footer from "./ui/Footer"

import Home from "./pages/Home"
import Collection from "./pages/Collection"
import Product from "./pages/Product"
import Basket from "./pages/Basket"
import Contacts from "./pages/Contacts"
import Faq from "./pages/Faq"
import AboutUs from "./pages/AboutUs"
import Support from "./pages/Support"
import Promotions from "./pages/Promotions"
import Feedbacks from "./pages/Feedbacks"
import PublicOfferAgreement from "./pages/PublicOfferAgreement"
import PrivacyPolicy from "./pages/PrivacyPolicy"

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: teal[500]
    }
  }
})

const App = ({ applySession }) => {
  axios.defaults.baseURL = process.env.REACT_APP_API

  useEffect(() => {
    ReactGA.initialize('UA-188364243-1')
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])

  useEffect(() => {
    axios(`/session/`, {
      withCredentials: true
    })
      .then(res => {
        console.log("[APP::LAUNCH]: ", res)
        res.data.isLoggedIn = true
        applySession(res.data)
      })
      .catch(err => {
        applySession({
          isLoggedIn: false
        })
      })
  }, [applySession])

  return (
    <ThemeProvider theme={theme}>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/contacts" component={Contacts} />
        <Route exact path="/faq" component={Faq} />
        <Route exact path="/publicOfferAgreement" component={PublicOfferAgreement} />
        <Route exact path="/privacyPolicy" component={PrivacyPolicy} />
        <Route exact path="/about-us" component={AboutUs} />
        <Route exact path="/support" component={Support} />
        <Route exact path="/basket" component={Basket} />
        <Route exact path="/promotions" component={Promotions} />
        <Route exact path="/feedbacks" component={Feedbacks} />
        <Route
          exact
          path="/collections/:collection_url/:category_url"
          component={props => <Collection {...props} />}
        />
        <Route
          exact
          path="/products/:id"
          component={props => <Product {...props} />}
        />
      </Switch>
      <Footer />
    </ThemeProvider>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    applySession: payload =>
      dispatch({ type: actions.APPLY_SESSION, payload: payload })
  }
}

export default connect(null, mapDispatchToProps)(App)
