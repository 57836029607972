import React, { useEffect, useState } from "react"
import axios from "axios"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Header from "../../ui/Header"
import Container from "../../ui/Container"
import LoaderSpinner from "../../ui/Loader"
import CollectionBlock from "./CollectionBlock"

import "./Collection.css"

const Collection = ({ match, translation, ...props }) => {
    const [collection, setCollection] = useState(null)

    const [page, setPage] = useState(parseInt(new URLSearchParams(props.location.search).get('page')) || 0)
    const [sort, setSort] = useState("descending")
    useEffect(() => {
        setPage(parseInt(new URLSearchParams(props.location.search).get('page')) || 0)
        window.scrollTo(0, 0)
    }, [props.location.search])

    const [loaded, setLoaded] = useState(true)

    const collectionURL = match.params.collection_url
    const categoryURL = match.params.category_url

    useEffect(() => {
        axios(`/collection/?collection=${collectionURL}&category=${categoryURL}&page=${page}&sort=${sort}&language=${translation.language}`).then(res => {
            setCollection(res.data)
            setLoaded(false)
        })
    }, [collectionURL, page, sort, categoryURL, translation.language])

    const paginatorDifference = () => collection.pagination.count - collection.pagination.page_size * (page + 1)

    if (!collection) {
        return (
            <div className="Collection">
                <Header />
                <div className={`${loaded ? "Collection-spinner" : "Collection-spinner-off"}`}>
                    <LoaderSpinner visible={loaded} />
                </div>
            </div>
        )
    }

    return (
        <div className="Collection">
            <Header />
            <CollectionBlock
                translation={translation}
                collection={collection}
                sort={sort}
                setSort={setSort}
            />
            <Container>
                <div className="Collection--paginator">
                    {page > 0 &&
                        <Link to={`/collections/${collectionURL}/${categoryURL}?page=${page - 1}`}>
                            <div className="Collection--paginator--previous">
                                <ArrowBackIcon style={{ fontSize: 22 }} />
                                {translation.language === "ua" && "Попередня"}
                                {translation.language === "ru" && "Предыдущая"}

                            </div>
                        </Link>
                    }
                    {paginatorDifference() > 0 &&
                        <Link to={`/collections/${collectionURL}/${categoryURL}?page=${page + 1}`}>
                            <div className="Collection--paginator--next">
                                {translation.language === "ua" && "Наступна"}
                                {translation.language === "ru" && "Следующая"}
                                <ArrowForwardIcon style={{ fontSize: 22 }} />
                            </div>
                        </Link>
                    }
                </div>
            </Container>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        translation: state.translationReducer,
    }
}

export default connect(mapStateToProps)(Collection)
