import * as actions from "./../actions"

const initialLang = localStorage.getItem("language") || "ua"

const initialState = {
  language: initialLang,
  translateKey: (obj, key) => {
    return obj[`${key}_${initialLang}`]
  },
}

export const translationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_LANGUAGE:
      localStorage.setItem("language", action.payload)
      return {
        ...initialState,
        language: action.payload,
        translateKey: (obj, key) => {
          return obj[`${key}_${action.payload}`]
        },
      }
    default:
      return state
  }
}
