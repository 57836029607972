import React, { useEffect } from "react"
import ReactDOM from "react-dom"
import { BrowserRouter, useLocation } from "react-router-dom"
import { Provider } from "react-redux"
import App from "./App"
import store from "./store"
import * as serviceWorker from "./serviceWorker"
import "./index.scss"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"



const ScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ScrollToTop />
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
