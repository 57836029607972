import React from "react"
import { connect } from "react-redux"

import Header from "./../ui/Header"
import Container from "./../ui/Container"
import PageTitle from "./../ui/PageTitle"
import { Typography } from "@material-ui/core"
import "./Support.css"

const Support = ({ translation }) => {
  return (
    <div className="Support">
      <Header />
      <PageTitle title={translation.language === "ua" ? "Підтримка" : "Поддержка"} />
      <Container>
        <div className="Support--content">
          <div className="Support--row">
            <div className="Support--title">
              <Typography variant="h5">Оплата</Typography>
            </div>
            <div className="Support--items">
              {translation.language === "ua" && (
                <Typography variant="body1" style={{ fontWeight: "bold" }}>
                  Ви можете оплатити замовлення на банківський рахунок
                </Typography>
              )}
              {translation.language === "ru" && (
                <Typography variant="body1" style={{ fontWeight: "bold" }}>
                  Вы можете оплатить заказ на банковский счет
                </Typography>
              )}
            </div>
          </div>
          <div className="Support--row">
            <div className="Support--title">
              <Typography variant="h5">Доставка</Typography>
            </div>
            <div className="Support--items">
              {translation.language === "ua" && (
                <Typography variant="body1" style={{ fontWeight: "bold" }}>
                  Доставка товарів здійснюється перевізником Нова Пошта. Всі замовлення , оформленні та оплачені до
                  12:00, відправляються в той же день. Якщо вартість замовлення більше 500 грн – доставка замовлення
                  безкоштовна.
                </Typography>
              )}
              {translation.language === "ru" && (
                <Typography variant="body1" style={{ fontWeight: "bold" }}>
                  Доставка товаров осуществляется перевозчиком Новая Почта. Все заказы, оформленные и оплаченные до
                  12 часов, отправляются в тот же день. Если стоимость заказа больше 500 грн – доставка заказа
                  бесплатная.
                </Typography>
              )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    translation: state.translationReducer
  }
}

export default connect(mapStateToProps)(Support)
