import React, { useState } from "react"

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  CircularProgress,
  MenuItem,
  InputLabel,
  Select
} from "@material-ui/core"

import AutoComplete from "./../AutoComplete"

import axios from "axios"
import SearchCityAutocomplete from "../SearchCityAutocomplete"
import SearchDepartmentAutocomplete from "../SearchDepartmentAutocomplete"

const AuthDialog = ({ onClose, translation, onLogIn }) => {
  const LOG_IN = "login"
  const REGISTER = "register"

  const [role, setRole] = useState(LOG_IN)

  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [payment, setPayment] = useState(2)
  const [city, setCity] = useState("")
  const [department, setDepartment] = useState("")

  const EMAIL = "email"
  const PHONE = "phone"

  const options = [
    {
      label: "Email",
      method: EMAIL
    },
    {
      label: "Телефон",
      method: PHONE
    }
  ]

  const [method, setMethod] = useState("")
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  // Validate email
  const validateEmail = email => {
    /* eslint-disable no-useless-escape */
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  const isValid = () => {
    if (role === LOG_IN) {
      if (method === EMAIL) {
        return validateEmail(email) && password.length >= 6
      }
      if (method === PHONE) {
        return phone !== "" && password.length >= 6
      }
    }
    if (role === REGISTER) {
      if (method === EMAIL) {
        return validateEmail(email) && name !== "" && password.length >= 6
      }
      if (method === PHONE) {
        return phone !== "" && name !== "" && password.length >= 6
      }
    }
  }

  const changeRole = () => {
    if (role === LOG_IN) {
      setRole(REGISTER)
    } else {
      setRole(LOG_IN)
    }
  }

  const emailLabel = () => {
    if (email.length === 0) {
      return "Email"
    } else {
      if (!validateEmail(email)) {
        return translation.language === "ua" ? "Хибний email" : "Неверный email"
      } else {
        return "Email"
      }
    }
  }

  const getError = () => {
    let errorText = ""

    if (error) {
      switch (error) {
        case "ERROR_TOO_SHORT_PASSWORD":
          errorText = "Занадто короткий пароль (мінімум 6)"
          break
        case "ERROR_USER_EXIST":
          errorText = "Користувач з такими даними вже існує"
          break
        case "ERROR_WRONG_METHOD":
          errorText = "Неправильний метод реєстрації / входу"
          break
        case "ERROR_NO_CREDENTIALS":
          errorText = "Не існує користувача з такими даними"
          break
        case "ERROR_WRONG_CREDENTIALS":
          errorText = "Неправильний пароль"
          break
        default:
          break
      }

      return (
        <Typography variant="button" className="AuthDialog--err">
          {errorText}
        </Typography>
      )
    }
  }

  const submitData = () => {
    setError(null)
    setLoading(true)

    const data = new FormData()

    if (role === REGISTER) {
      data.append("name", name)
      data.append("payment", payment)
      data.append("city", city)
      data.append("department", department)
    }

    data.append("method", method)
    data.append("phone", phone)
    data.append("email", email)
    data.append("password", password)

    axios
      .post(`/${role}/`, data, {
        withCredentials: true
      })
      .then(res => {
        res.data.isLoggedIn = true
        setError(null)
        onLogIn(res.data)
        onClose()
      })
      .catch(err => {
        setLoading(false)
        console.log(err.response)
        setError(err.response.data)
      })
  }

  const handlePayment = (event) => {
    setPayment(event.target.value);
  };

  return (
    <Dialog onClose={onClose} open={true} fullWidth>
      <DialogTitle>
        {role === LOG_IN
          ? translation.language === "ua"
            ? "Вхід в аккаунт"
            : "Вход в аккаунт"
          : translation.language === "ua"
            ? "Реєстрація"
            : "Регистрация"}
      </DialogTitle>
      <DialogContent>
        <AutoComplete
          options={options}
          onChange={option => setMethod(option.method)}
          getOptionLabel={option => option.label}
          label={
            role === LOG_IN
              ? translation.language === "ua"
                ? "Спосіб входу"
                : "Вариант входа"
              : translation.language === "ua"
                ? "Спосіб реєстрації"
                : "Способ регистрации"
          }
          required
        />
        {method !== "" ? (
          <>
            {role === REGISTER && (
              <TextField
                label={translation.language === "ua" ? "Ім'я" : "Им'я"}
                value={name}
                onChange={e => setName(e.target.value)}
                fullWidth
                margin="dense"
                required
              />
            )}
            {method === "email" ? (
              <TextField
                label={emailLabel()}
                value={email}
                onChange={e => setEmail(e.target.value)}
                fullWidth
                margin="dense"
                required
              />
            ) : (
              <TextField
                label={
                  translation.language === "ua"
                    ? "Номер телефону"
                    : "Телефонный номер"
                }
                value={phone}
                onChange={e => setPhone(e.target.value)}
                fullWidth
                margin="dense"
                required
              />
            )}
            <TextField
              label="Пароль"
              onChange={e => setPassword(e.target.value)}
              fullWidth
              margin="dense"
              type="password"
              required
            />
            {role === REGISTER &&
              <>
                <InputLabel id="demo-simple-select-label">{translation.language === "ua"
                  ? "Спосіб оплати"
                  : "Способ оплаты"
                }</InputLabel>
                <Select
                  style={{ width: "100%" }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={payment}
                  onChange={handlePayment}
                >
                  <MenuItem value={1}>{translation.language === "ua"
                    ? "Оплата на банківський рахунок"
                    : "Оплата на банковский счет"
                  }</MenuItem>
                  {/* <MenuItem value={2}>{translation.language === "ua"
                    ? "Оплата при отриманні товару"
                    : "Оплата при получении товара"
                  }</MenuItem> */}
                </Select>
                <SearchCityAutocomplete onChange={city => setCity(city.id)} />
                {city !== "" && <SearchDepartmentAutocomplete city={city} onChange={department => setDepartment(department.id)} />}
              </>}
            {loading && <CircularProgress />}
            {getError()}
          </>
        ) : null}
        <DialogActions>
          <Button color="primary" onClick={changeRole}>
            {role === LOG_IN
              ? translation.language === "ua"
                ? "Не зареєстровані"
                : "Не зарегистрированы"
              : translation.language === "ua"
                ? "В мене є аккаунт"
                : "У меня есть аккаунт"}
          </Button>
          <Button
            color="primary"
            onClick={submitData}
            variant="contained"
            disabled={!isValid()}
            disableElevation
          >
            {role === LOG_IN
              ? translation.language === "ua"
                ? "Увійти"
                : "Войти"
              : translation.language === "ua"
                ? "Зареєструватися"
                : "Зарегистрироваться"}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default AuthDialog
