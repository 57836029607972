import React, { useState, useEffect } from "react"
import axios from "axios"
import { connect } from "react-redux"
import * as actions from "./../../store/actions"

import { Link } from "react-router-dom"
import { HashLink } from "react-router-hash-link"

import { MenuItem, Button, IconButton } from "@material-ui/core"

import {
  ExpandLess,
  ExpandMore,
  Person as PersonIcon,
  Menu as MenuIcon,
  Close,
  Search
} from "@material-ui/icons"


import Menu from "./../Menu"

import AuthDialog from "./AuthDialog"
import ActionsDialog from "./ActionsDialog"
import SearchDialog from "./SearchDialog"

import "./Header.scss"

const HeaderItem = props => {
  const {
    item,
    onClick,
    setOpened,
    expanded,
    translation
  } = props

  return (
    <span
      onClick={onClick}
      className={`header-category-title${expanded ? " hovered" : ""}`}
      key={item.category_group.id}
    >
      <div className='header-category-name'>
        {translation.translateKey(item.category_group, "name")}
        {expanded ? <ExpandLess /> : <ExpandMore />}
      </div>
      <div className="header-category-list">
        {item.categories.map(category => {
          return (
            <HashLink
              onClick={() => {
                onClick()
                setOpened(false)
              }}
              key={category.id}
              to={
                "/collections/" + item.category_group.url + "/" + category.url
              }
            >
              <MenuItem>{translation.translateKey(category, "name")}</MenuItem>
            </HashLink>
          )
        })}
      </div>
    </span>
  )
}

const Header = props => {
  const {
    isLoggedIn,
    sessionUser,
    sessionBasket,
    sessionOrders,
    deleteFromBasket,
    onLogIn,
    onLogOut,
    translation,
    setLanguage
  } = props

  const [headerItems, setHeaderItems] = useState([])
  const [itemExpanded, setItemExpanded] = useState(-1)
  const [opened, setOpened] = useState(false)

  const [showAuthDialog, setShowAuthDialog] = useState(false)
  const [showActionsDialog, setShowActionsDialog] = useState(false)
  const [showSearchDialog, setShowSearchDialog] = useState(false)

  const [menuAnchor, setMenuAnchor] = useState(null)

  useEffect(() => {
    axios(`/nav/`, {
      withCredentials: true,
      crossdomain: true
    }).then(res => {
      setHeaderItems(res.data)
    })
  }, [])

  const logOut = () => {
    axios(`/logout/`, { withCredentials: true })
      .then(res => {
        onLogOut()
      })
      .catch(err => { })
  }

  const handleGroupClick = (linkIndex) => {
    linkIndex === itemExpanded
      ? setItemExpanded(-1)
      : setItemExpanded(linkIndex)
  }

  return (
    <header style={{ display: "flex" }}>
      {showAuthDialog && (
        <AuthDialog
          onLogIn={onLogIn}
          translation={translation}
          onClose={() => setShowAuthDialog(false)}
        />
      )}
      {showActionsDialog && (
        <ActionsDialog
          sessionOrders={sessionOrders}
          translation={translation}
          onLogOut={logOut}
          onClose={() => setShowActionsDialog(false)}
          isLoggedIn={isLoggedIn}
          section={!props.isLoggedIn ? 1 : null}
          deleteFromBasket={deleteFromBasket}
          sessionBasket={sessionBasket}
          sessionUser={sessionUser}
        />
      )}
      {showSearchDialog && (
        <SearchDialog open={showSearchDialog} onClose={e => {
          e.stopPropagation()
          setShowSearchDialog(false)
        }} />
      )}

      <IconButton className="header-burger" onClick={() => setOpened(!opened)}>
        {opened ? <Close /> : <MenuIcon />}
      </IconButton>
      <nav className="header-nav">
        <div className={`header-nav-content${opened ? " opened" : ""}`}>
          <div className="header-nav-adaptive-cont" style={{ alignItems: "center" }}>

            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <div className="header-logo"></div>
              <span className="header-category-title">
                <Link to="/" onClick={() => setOpened(false)}>
                  {translation.language === "ua" && "Головна"}
                  {translation.language === "ru" && "Главная"}
                </Link>
              </span>
            </div>
            <Link to="/promotions" onClick={() => setOpened(false)}>
              <span className="header-category-title">
                <div className="header-category-name">
                  {translation.language === "ua" && "Акційні товари"}
                  {translation.language === "ru" && "Акционные товары"}
                </div>
              </span>
            </Link>
            <Link to="/feedbacks" onClick={() => setOpened(false)}>
              <span className="header-category-title">
                <div className="header-category-name">

                  {translation.language === "ua" && "Відгуки"}
                  {translation.language === "ru" && "Отзывы"}
                </div>
              </span>
            </Link>
            {headerItems.map((item, index) => (
              <HeaderItem
                key={item.category_group.id}
                translation={translation}
                onClick={() => handleGroupClick(index)}
                setOpened={setOpened}
                item={item}
                expanded={index === itemExpanded}
              />
            ))}
          </div>
        </div>
      </nav>
      <div className="header-widgets">
        <IconButton onClick={() => setShowSearchDialog(true)}>
          <Search />
        </IconButton>
        {!isLoggedIn ? (
          <>
            <Button
              onClick={() => setShowAuthDialog(true)}
              variant="text"
              color="primary"
            >
              {translation.language === "ua" && "Увійти"}
              {translation.language === "ru" && "Войти"}
            </Button>
            <Button
              onClick={() => setShowActionsDialog(true)}
              variant="text"
              color="primary"
            >
              {translation.language === "ua" && `Кошик(${sessionBasket.length})`}
              {translation.language === "ru" && `Корзина(${sessionBasket.length})`}
            </Button>
          </>
        ) : (
          <>
            <div className="user-account">
              <IconButton
                onClick={() => setShowActionsDialog(true)}
                variant="text"
                color="primary"
              >
                <PersonIcon />
              </IconButton>
            </div>

          </>
        )}
        <Button
          variant="text"
          color="primary"
          onClick={e => setMenuAnchor(e.target)}
        >
          {translation.language}
        </Button>
        <div className="Header--language">
          <Menu
            anchorEl={menuAnchor}
            onClose={() => setMenuAnchor(null)}
            open={Boolean(menuAnchor)}
          >
            <MenuItem
              onClick={() => {
                setLanguage("ua")
                setMenuAnchor(null)
              }}
            >
              UA
            </MenuItem>
            <MenuItem
              onClick={() => {
                setLanguage("ru")
                setMenuAnchor(null)
              }}
            >
              RU
            </MenuItem>
          </Menu>
        </div>
      </div>
    </header>
  )
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.sessionReducer.isLoggedIn,
    sessionUser: state.sessionReducer.user,
    sessionOrders: state.sessionReducer.orders,
    sessionBasket: state.sessionReducer.basket,
    translation: state.translationReducer
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onLogIn: logInPayload =>
      dispatch({ type: actions.APPLY_SESSION, payload: logInPayload }),
    onLogOut: () => dispatch({ type: actions.LOG_OUT }),
    setLanguage: language =>
      dispatch({ type: actions.SET_LANGUAGE, payload: language }),
    deleteFromBasket: itemID =>
      dispatch({ type: actions.DELETE_FROM_BASKET, payload: itemID })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
