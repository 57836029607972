import React from "react"
import { connect } from "react-redux"

import Header from "./../ui/Header"
import { Typography } from "@material-ui/core"
import PageTitle from "./../ui/PageTitle"

import "./Contacts.css"

const Contacts = ({ translation }) => {
  return (
    <div className="Contacts">
      <Header />
      <PageTitle title={translation.language === "ua" ? "Контакти" : "Контакты"} />
      <div className="Contacts--content">
        <div className="Contacts--row">
          <div className="Contact--title">
            <Typography variant="h5">
              {translation.language === "ua" && "Адреса"}
              {translation.language === "ru" && "Адрес"}
            </Typography>
          </div>
          <div className="Contacts--items">
            <Typography variant="body1">
              {translation.language === "ua" && "м. Чернівці, вул. Коломийська 9Д"}
              {translation.language === "ru" && "г. Черновцы, ул. Коломыйская 9Д"}
            </Typography>
          </div>
        </div>
        <div className="Contacts--row">
          <div className="Contact--title">
            <Typography variant="h5">
              {translation.language === "ua" && "Зв'яжіться з нами"}
              {translation.language === "ru" && "Свяжитесь с нами"}
            </Typography>
          </div>
          <div className="Contacts--items">
            <Typography variant="body1">
              <span className="bold">
                {translation.language === "ua" && "Телефони: "}
                {translation.language === "ru" && "Телефоны: "}
              </span>
              0372 54 38 90, (067) 37 24 364, (097) 22 36 790
            </Typography>
            <Typography variant="body1">
              <span className="bold">Факс: </span>
              0372 54 38 89
            </Typography>
            <Typography variant="body1">
              {" "}
              <span className="bold">Емейл: </span>
              manager@yva.com.ua
            </Typography>
          </div>
        </div>
        <div className="Contacts--row">
          <div className="Contact--title">
            <Typography variant="h5">
              {translation.language === "ua" && "Розклад"}
              {translation.language === "ru" && "Время работы"}
            </Typography>
          </div>
          <div className="Contacts--items">
            <Typography variant="body1">
              <span className="bold">
                {translation.language === "ua" && "Понеділок - П'ятниця: "}
                {translation.language === "ru" && "Понедельник - П'ятница: "}
              </span>
              08:30 - 17:20
            </Typography>
            <Typography variant="body1">
              {translation.language === "ua" && (
                <>
                  <span className="bold">Субота: </span>
                  вихідний
                </>
              )}
              {translation.language === "ru" && (
                <>
                  <span className="bold">Суббота: </span>
                  выходной
                </>
              )}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    translation: state.translationReducer
  }
}

export default connect(mapStateToProps)(Contacts)
