import React from "react";
import "./PageTitle.css";

const PageTitle = ({ title, homeScreen }) => {
  return (
    <div className={!homeScreen ? "PageTitle" : "PageTitle MainTitle"}>
      <h1>{title}</h1>
    </div>
  );
};

export default PageTitle;
