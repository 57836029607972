import React, { useEffect, useState } from 'react'
import SearchCityAutocomplete from '../SearchCityAutocomplete'
import SearchDepartmentAutocomplete from '../SearchDepartmentAutocomplete'

const DeliveryDetailsForm = (props) => {
  const { sessionUser, onChange } = props

  const [city, setCity] = useState(sessionUser.city)
  const [department, setDepartment] = useState(sessionUser.department)

  useEffect(() => {
    onChange(city, department)
  }, [city, department, onChange])

  const handleCityChange = (city) => {
    setCity(city)
    setDepartment(null)
  }

  const handleDepartmentChange = (department) => {
    setDepartment(department)
  }

  return <React.Fragment>
    <SearchCityAutocomplete
      value={city}
      onChange={handleCityChange}
    />
    <SearchDepartmentAutocomplete
      value={department}
      onChange={handleDepartmentChange}
      city={city ? city.id : ''}
    />
  </React.Fragment>
}

export default DeliveryDetailsForm