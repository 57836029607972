import React, { useState, useEffect } from "react"
import axios from "axios"
import { connect } from "react-redux"
import { Button, Menu, MenuItem } from "@material-ui/core";
import { Link } from "react-router-dom"
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Header from "../../ui/Header"
import Container from "../../ui/Container"
import LoaderSpinner from "../../ui/Loader"
import ProductCard from "../../ui/ProductCard";

const sortString = {
    "descending": {
        "ua": "За ціною (спочатку дорожчі)",
        "ru": "За ценой (сначала дороже)",
    },
    "ascending": {
        "ua": "За ціною (спочатку дешевші)",
        "ru": "За ценой (сначала дешевле)"
    },
    "alphabetical": {
        "ua": "За назвою",
        "ru": "По названию"
    }
}

const Promotions = ({ match, translation, ...props }) => {
    const [promotionsPage, setPromotionsPage] = useState({
        count: 0,
        pageSize: 10,
        products: []
    })

    const [page, setPage] = useState(parseInt(new URLSearchParams(props.location.search).get('page')) || 0)
    const [sort, setSort] = useState("descending")
    useEffect(() => {
        setPage(parseInt(new URLSearchParams(props.location.search).get('page')) || 0)
        window.scrollTo(0, 0)
    }, [props.location.search])
    const [loaded, setLoaded] = useState(true)

    const [menuAnchor, setMenuAnchor] = useState(null)

    const applySort = s => {
        setSort(s)
        setMenuAnchor(null)
    }

    useEffect(() => {
        axios(`/with_discount/?page=${page}&sort=${sort}&language=${translation.language}`).then(res => {
            setPromotionsPage(res.data)
            setLoaded(false)
        })
    }, [page, sort, translation.language])

    const paginatorDifference = () => promotionsPage.count - promotionsPage.pageSize * (page + 1)

    if (!promotionsPage) {
        return (
            <div className="Collection">
                <Header />
                <div className={`${loaded ? "Collection-spinner" : "Collection-spinner-off"}`}>
                    <LoaderSpinner visible={loaded} />
                </div>
            </div>
        )
    }

    return (
        <div className="Collection">
            <Header />
            <div className="Collection--category">
                <div className="Collection--category--info">
                    <h2>
                        {translation.language === "ua" && "Акційні товари"}
                        {translation.language === "ru" && "Акционные товары"}
                    </h2>
                    <Container>
                        <p>
                            {translation.language === "ua" && "Акційні товари"}
                            {translation.language === "ru" && "Акционные товары"}
                        </p>
                    </Container>
                </div>
                <Container>
                    <div className="Collection--category--actions">
                        <div className="Collection--category--action Collection--category--sort">
                            <p className="Collection--category--filter--name">
                                {translation.language === "ua" && "Сортування"}
                                {translation.language === "ru" && "Сортировка"}
                            </p>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={e => setMenuAnchor(e.target)}
                                style={{ marginRight: "10px" }}
                            >
                                {sortString[sort][translation.language]}
                            </Button>
                            <Menu
                                anchorEl={menuAnchor}
                                open={Boolean(menuAnchor)}
                                onClose={() => setMenuAnchor(null)}
                            >
                                <MenuItem onClick={() => applySort("descending")}>
                                    {sortString["descending"][translation.language]}
                                </MenuItem>
                                <MenuItem onClick={() => applySort("ascending")}>
                                    {sortString["ascending"][translation.language]}
                                </MenuItem>
                                <MenuItem onClick={() => applySort("alphabetical")}>
                                    {sortString["alphabetical"][translation.language]}
                                </MenuItem>
                            </Menu>
                        </div>
                    </div>
                    <div className="Collection--items">
                        {promotionsPage.products.map(product => (
                            <ProductCard
                                key={product.id}
                                translation={translation}
                                product={product}
                            />
                        ))}
                    </div>
                </Container>
            </div>
            <Container>
                <div className="Collection--paginator">
                    {page > 0 &&
                        <Link to={`/promotions/?page=${page - 1}`}>
                            <div className="Collection--paginator--previous">
                                <ArrowBackIcon style={{ fontSize: 22 }} />
                                {translation.language === "ua" && "Попередня"}
                                {translation.language === "ru" && "Предыдущая"}

                            </div>
                        </Link>
                    }
                    {paginatorDifference() > 0 &&
                        <Link to={`/promotions/?page=${page + 1}`}>
                            <div className="Collection--paginator--next">
                                {translation.language === "ua" && "Наступна"}
                                {translation.language === "ru" && "Следующая"}
                                <ArrowForwardIcon style={{ fontSize: 22 }} />
                            </div>
                        </Link>
                    }
                </div>
            </Container>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        translation: state.translationReducer,
    }
}

export default connect(mapStateToProps)(Promotions)