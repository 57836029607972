import * as actions from "./../actions"

const initialState = {
  dataLoaded: false,
  isLoggedIn: false,
  basket: [],
  orders: [],
  user: {}
}

export const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.APPLY_SESSION:
      return {
        ...state,
        dataLoaded: true,
        isLoggedIn: action.payload.isLoggedIn,
        basket: action.payload.basket
          ? action.payload.basket
          : JSON.parse(localStorage.getItem("basket")) || [],
        orders: action.payload.orders ? action.payload.orders : [],
        user: action.payload.user ? action.payload.user : {}
      }

    case actions.LOG_OUT:
      return {
        ...state,
        ...initialState,
        basket: JSON.parse(localStorage.getItem("basket")) || []
      }

    case actions.STORE_BASKET:
      const basketItem = state.basket.findIndex(
        item => item.product.id === action.payload.product.id
      )
      const storeBasketCopy = [...state.basket]

      if (basketItem >= 0) {
        storeBasketCopy[basketItem].info.quantity +=
          action.payload.info.quantity
      } else {
        storeBasketCopy.push(action.payload)
      }

      // Backup to localStorage if not loggedIn
      if (!state.isLoggedIn) {
        localStorage.setItem("basket", JSON.stringify(storeBasketCopy))
      }

      return {
        ...state,
        basket: storeBasketCopy
      }

    case actions.UPDATE_BASKET_ITEM_QUANTITY:
      const updateBasketItemCopy = [...state.basket]
      const updateItem = updateBasketItemCopy.findIndex(
        item => item.product.id === action.payload.itemID
      )

      if (updateItem >= 0) {
        updateBasketItemCopy[updateItem].info.quantity = action.payload.quantity
      }

      // Backup to localStorage if not loggedIn
      if (!state.isLoggedIn) {
        localStorage.setItem("basket", JSON.stringify(updateBasketItemCopy))
      }

      return {
        ...state,
        basket: updateBasketItemCopy
      }

    case actions.DELETE_FROM_BASKET:
      const deleteFromBasketCopy = state.basket.filter(
        item => item.product.id !== action.payload
      )

      // Backup to localStorage if not loggedIn
      if (!state.isLoggedIn) {
        localStorage.setItem("basket", JSON.stringify(deleteFromBasketCopy))
      }

      return {
        ...state,
        basket: deleteFromBasketCopy
      }

    // Clear basket
    case actions.CLEAR_BASKET:
      if (!state.isLoggedIn) {
        localStorage.removeItem("basket")
      }
      return {
        ...state,
        basket: []
      }

    // Profile updates
    case actions.UPDATE_PROFILE:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload
        }
      }

    default:
      return state
  }
}
