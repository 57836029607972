import React, { useState } from "react"

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core"

import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import axios from "axios"

const FeedbackDialog = ({ translation, onClose, product_id, onSend, user }) => {

    const [rate, setRate] = useState(0);
    const [text, setText] = useState('')
    const [visitorName, setVisitorName] = useState("")


    const handleSubmitData = (e) => {
        e.preventDefault()

        let data = new FormData()

        data.append("product_id", product_id)
        data.append("rate", rate)
        data.append("text", text)

        if (Object.keys(user).length === 0) {
            console.log("Anon user feedback")
            data.append("userId", user.id)
            data.append("userName", visitorName)
        } else {
            data.append("userId", user.id)
            data.append("userName", user.name)
        }

        axios.post("/feedback/", data, { withCredentials: true }).then(res => {
            console.log(res.data)
            onSend(res.data)
        }).finally(onClose())
    }

    return (
        <Dialog onClose={onClose} open={true} fullWidth>
            <DialogTitle>
                {translation.language === "ua" ? "Залиште відгук" : "Оставьте отзыв"}
            </DialogTitle>
            <DialogContent>
                {Object.keys(user).length === 0 && (
                    <TextField
                        id="standard-multiline-flexible"
                        label={translation.language === "ua" ? "Ваше ім'я" : "Ваше им'я"}
                        multiline
                        rowsMax={6}
                        value={visitorName}
                        onChange={(e) => setVisitorName(e.target.value)}
                    />
                )}
                <TextField
                    id="standard-multiline-flexible"
                    label={translation.language === "ua" ? "Ваш відгук" : "Ваш отзыв"}
                    multiline
                    rowsMax={6}
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                />
                <Box component="fieldset" mb={3} borderColor="transparent">
                    <Rating
                        name="simple-controlled"
                        rate={rate}
                        onChange={(event, newValue) => {
                            setRate(newValue);
                        }}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button color="primary"
                    onClick={handleSubmitData}
                    variant="contained"
                    disableElevation
                >
                    {translation.language === "ua" ? "Залишти" : "Оставить"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default (FeedbackDialog)
