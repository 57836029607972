import React, { useState, useEffect } from "react"
import axios from "axios"
import { connect } from "react-redux"
import { Rating } from '@material-ui/lab';
import Header from "../../ui/Header"
import { Link } from "react-router-dom";
import "./index.css"



const Feedbacks = ({ translation, ...props }) => {

    const [messages, setMessages] = useState([])
    useEffect(() => {
        axios(`/get_all_feedbacks/`).then(res => {
            const sortedData = res.data.sort((a, b) => b.time - a.time)
            setMessages(m => m.concat(sortedData))
        })
    }, [])

    return (
        <div className="Collection">
            <Header />
            <div className="Collection--category">
                <div className="Collection--category--info">
                    <h2>
                        {translation.language === "ua" && "Відгуки"}
                        {translation.language === "ru" && "Отзывы"}
                    </h2>
                </div>
                <div className="Product--feedback-messages" style={{ marginTop: "100px" }}>
                    {messages.map(message => {
                        return (
                            <Link key={message.id} to={`/products/${message.product_id}`}
                                style={{
                                    color: "initial",
                                    textDecoration: "none"
                                }}>




                                <div className="feedback-message feedback-page-message">
                                    <div
                                        className="Collection--item--image"
                                        style={{
                                            background:
                                                "url(" +
                                                process.env.REACT_APP_API +
                                                "/media/" +
                                                message.product_image.split(",")[0] +
                                                ")",
                                            height: "200px",
                                            width: "200px",
                                            margin: "unset",
                                            marginRight: "50px"
                                        }}
                                    ></div>
                                    <div className="feedback-container">
                                        <div className="feedback-top">
                                            <div>
                                                <p style={{ margin: 0 }}>{message.user_name}</p>
                                                <span>{new Date(message.time * 1000).toLocaleDateString()}</span>
                                            </div>

                                            <Rating name="read-only" value={message.rate} readOnly />
                                        </div>
                                        <div className="feedback-bottom">
                                            <div className="feedback-text">
                                                <p>{message.text}</p>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </Link>
                        )

                    })}
                </div>
            </div>



        </div >
    )
}

const mapStateToProps = state => {
    return {
        translation: state.translationReducer,
    }
}

export default connect(mapStateToProps)(Feedbacks)