import React, { useState } from "react"

import { Typography, TextField, IconButton } from "@material-ui/core"

import {
  Delete as DeleteIcon,
  Add as AddIcon,
  Remove as RemoveIcon
} from "@material-ui/icons"

const Item = ({ info, onDelete, onQuantityUpdate, product, translation }) => {
  const [quantity, setQuantity] = useState(info.quantity)

  const decrease = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1)
      onQuantityUpdate(info.id, product.id, quantity - 1)
    }
  }

  const increase = () => {
    if (quantity <= 50) {
      setQuantity(quantity + 1)
      onQuantityUpdate(info.id, product.id, quantity + 1)
    }
  }

  const change = e => {
    let newQuantity = parseInt(e.target.value, 10)

    console.log("Change: ", e.target.value, newQuantity)

    if (isNaN(newQuantity)) {
      setQuantity(0)
    } else {
      if (newQuantity <= 0 || newQuantity > 50) {
        newQuantity = 1
      }

      setQuantity(newQuantity)
      onQuantityUpdate(info.id, product.id, newQuantity)
    }
  }

  return (
    <div className="item">
      <div className="item__info basket__item">
        <div className="photo__item">
          <img
            style={{ height: 90, width: 90 }}
            src={`${process.env.REACT_APP_API}/media/${product.picture.split(",")[0]
              }`}
            alt=""
          />
        </div>
        <Typography variant="body1">
          {translation.translateKey(product, "name")}
        </Typography>
      </div>
      <div className="price__item basket__item">
        <Typography variant="body1">
          {product.with_action ? product.new_price.toLocaleString() : product.price.toLocaleString()} ГРН
        </Typography>
      </div>
      <div className="quantity basket__item">
        <IconButton
          onClick={decrease}
          className="delete__item"
          style={{ fontSize: 32 }}
        >
          <RemoveIcon />
        </IconButton>
        <TextField
          variant="outlined"
          error={!quantity}
          label={quantity ? "" : "Введіть число"}
          value={quantity ? quantity : ""}
          onChange={change}
          margin="dense"
          type="number"
        />
        <IconButton
          onClick={increase}
          className="add__item"
          style={{ fontSize: 32 }}
        >
          <AddIcon />
        </IconButton>
      </div>
      <div className="total__item basket__item">
        <Typography variant="body1">
          {(quantity * (product.with_action ? product.new_price : product.price)).toLocaleString()} ГРН
        </Typography>
      </div>
      <div className="delete basket__item">
        <IconButton onClick={onDelete}>
          <DeleteIcon className="remove__item" />
        </IconButton>
      </div>
    </div>
  )
}

export default Item
