import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import axios from "axios"
import DeleteIcon from '@material-ui/icons/Delete';


import { Button, IconButton, Snackbar, Typography } from "@material-ui/core"
import { ChevronLeft, ChevronRight } from "@material-ui/icons"
import { Rating } from '@material-ui/lab';


import Header from "./../ui/Header"
import Container from "./../ui/Container"
import BuyWidget from "./../ui/BuyWidget"
import FeedbackDialog from "./FeedbackDialog";

import DialogCall from "./../ui/DialogCall"

import {
    ExpandLess,
    ExpandMore,
} from "@material-ui/icons"

import "./Product.css"


const ProductCarousel = ({ picturesString, certificate }) => {
    const [index, setIndex] = useState(0)
    const pictures = picturesString.split(",")

    const inc = () =>
        index < pictures.length - 1 ? setIndex(c => c + 1) : setIndex(0)
    const dec = () =>
        index > 0 ? setIndex(c => c - 1) : setIndex(pictures.length - 1)

    return (
        <div className={certificate ? "Product--carousel no-width" : "Product--carousel"}>
            <div className="Product--carousel--container">
                {pictures.map((picture, idx) => {
                    return (
                        <div
                            key={picture}
                            className={`Product--carousel--slide${idx === index ? " current" : ""
                                }`}
                            style={{
                                background:
                                    "url(" + process.env.REACT_APP_API + "/media/" + picture + ")"
                            }}
                        ></div>
                    )
                })}
            </div>
            {pictures.length > 1 && (
                <>
                    <div className="Product--carousel--controls">
                        <IconButton onClick={dec}>
                            <ChevronLeft />
                        </IconButton>
                        <IconButton onClick={inc}>
                            <ChevronRight />
                        </IconButton>
                    </div>
                    <div className="Product--carousel--indicator">
                        {pictures.map((_, key) => {
                            return (
                                <div
                                    key={key}
                                    onClick={() => setIndex(key)}
                                    className={`Product--carousel--dot${index === key ? " current" : ""
                                        }`}
                                ></div>
                            )
                        })}
                    </div>
                </>
            )}
        </div>
    )
}

const Product = ({ match, translation, isLoggedIn, userId }) => {
    const [product, setProduct] = useState(null)
    const [snackBarOpen, setSnackBarOpen] = useState(false)
    const [showFeedbackDialog, setShowFeedbackDialog] = useState(false)
    const [messages, setMessages] = useState([])
    const [offset, setOffset] = useState(0)
    const [open, setOpen] = useState(false)
    const [expandedDes, setExpandedDes] = useState(false)
    const [expandedUsage, setExpandedUsage] = useState(false)
    const [expandedContent, setExpandedContent] = useState(false)
    const [expandedCertificate, setExpandedCertificate] = useState(false)

    // HACK
    const descriptionRef = useRef()
    const id = match.params.id

    useEffect(() => {
        axios(`/product/?id=${id}`).then(res => {
            setProduct(res.data)
        })
    }, [id])

    useEffect(() => {
        if (product) {
            if (expandedDes) {
                descriptionRef.current.innerHTML = translation.translateKey(
                    product,
                    "description"
                )
            } else descriptionRef.current.innerHTML = ""

        }
    }, [product, expandedDes, translation])


    useEffect(() => {
        axios(`/get_feedback/?product_id=${id}&offset=${offset}`).then(res => {
            console.log(res.data)
            setMessages(m => m.concat(res.data))
        })
    }, [id, offset])


    const sizeTypes = {
        1: "мл",
        2: "мг",
        3: "г",
        4: "шт"
    }

    const handleLoadMore = () => {
        setOffset(offset => offset + 1)
    }


    const handleDeleteFeedback = (feedbackId) => {
        let data = new FormData()

        data.append("feedback_id", feedbackId)

        axios.post("/delete_feedback/", data, { withCredentials: true }).then(res => {
            let updatedMsgs = messages.filter((msg) => msg.id !== res.data.id)
            setMessages(updatedMsgs)
        }).finally(setSnackBarOpen(false))
    }

    const handleExpandedUsage = () => {
        setExpandedUsage(!expandedUsage)
        // eslint-disable-next-line no-undef
        gtag('event', 'conversion', {
            'send_to': 'AW-659765647/EDiNCKuBz_gBEI_zzLoC',
            'event_callback': null
        });
    }

    const handleExpadedDes = () => {
        setExpandedDes(!expandedDes)
        // eslint-disable-next-line no-undef
        gtag('event', 'conversion', {
            'send_to': 'AW-659765647/EDiNCKuBz_gBEI_zzLoC',
            'event_callback': null
        });
    }

    const handleExpadedContent = () => {
        setExpandedContent(!expandedContent)
        // eslint-disable-next-line no-undef
        gtag('event', 'conversion', {
            'send_to': 'AW-659765647/EDiNCKuBz_gBEI_zzLoC',
            'event_callback': null
        });
    }

    const handleExpadedCertificate = () => {
        setExpandedCertificate(!expandedCertificate)
        // eslint-disable-next-line no-undef
        gtag('event', 'conversion', {
            'send_to': 'AW-659765647/EDiNCKuBz_gBEI_zzLoC',
            'event_callback': null
        });
    }

    return (
        <div className="Product">
            <Header />
            <DialogCall pr={false} open={open} setOpen={setOpen} />
            <Container>
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left"
                    }}
                    open={snackBarOpen}
                    autoHideDuration={6000}
                    onClose={() => setSnackBarOpen(false)}
                    message="Товар добавлено в корзину"
                />
                {product && (
                    <div className="Product">
                        <div className="Product--lot">
                            <ProductCarousel picturesString={product.picture} />
                            <div className="Product--lot--info">
                                <div>
                                    <h1>{translation.translateKey(product, "name")}</h1>
                                    <span>
                                        {product.with_action === true ?
                                            <>
                                                <p className='sales_price' style={{ fontWeight: 600 }}>{product.price.toLocaleString()} ГРН </p>
                                                <p className="new_price no_margin_btm">{product.new_price.toLocaleString()} ГРН</p>
                                            </>
                                            : <p style={{ fontWeight: 600 }}>{product.price.toLocaleString()} ГРН</p>
                                        }
                                        {product.size.toLocaleString()} {sizeTypes[product.size_type]}
                                        {product.additional_size !== 0 && <span> + </span>}
                                        {product.additional_size !== 0 && product.additional_size}
                                        {product.additional_size !== 0 && " " + sizeTypes[product.size_type]}
                                        {product.firm_ua &&
                                            <p> {translation.language === "ua" && "Виробник: "}
                                                {translation.language === "ru" && "Производитель: "}
                                                {translation.language === "ua" && product.firm_ua}
                                                {translation.language === "ru" && product.firm_ru}
                                            </p>
                                        }
                                        {product.is_gift &&
                                            <p style={{ color: "rgb(181, 175, 0)" }}> {translation.language === "ua" && "Подарунок: "}
                                                {translation.language === "ru" && "Подарок: "}
                                                {translation.language === "ua" && product.gift_ua}
                                                {translation.language === "ru" && product.gift_ru}
                                            </p>
                                        }
                                    </span>
                                </div>
                                <span>{translation.translateKey(product, "short")}</span>
                                <BuyWidget
                                    available={product.is_available}
                                    mode="column"
                                    product={product}
                                    onClick={() => setSnackBarOpen(true)}
                                />
                            </div>
                        </div>
                        <div className="Product--additional">
                            <div>
                                <Typography variant="h6">
                                    {translation.language === "ua" && "Опис"}
                                    {translation.language === "ru" && "Описание"}
                                </Typography>
                                {expandedDes
                                    ?
                                    <IconButton onClick={handleExpadedDes}>
                                        <ExpandLess />
                                    </IconButton>
                                    :
                                    <IconButton onClick={handleExpadedDes}>
                                        <ExpandMore />
                                    </IconButton>
                                }
                                <Typography variant="body1" ref={descriptionRef}></Typography>
                            </div>
                            <div>
                                <Typography variant="h6">
                                    {translation.language === "ua" && "Використання"}
                                    {translation.language === "ru" && "Использование"}
                                </Typography>
                                {expandedUsage
                                    ?
                                    <IconButton onClick={handleExpandedUsage}>
                                        <ExpandLess />
                                    </IconButton>
                                    :
                                    <IconButton onClick={handleExpandedUsage}>
                                        <ExpandMore />
                                    </IconButton>
                                }
                                <Typography variant="body1">
                                    {expandedUsage && translation.translateKey(product, "instruction")}
                                </Typography>
                            </div>
                            <div>
                                <Typography variant="h6">
                                    {translation.language === "ua" && "Склад"}
                                    {translation.language === "ru" && "Состав"}
                                </Typography>
                                {expandedContent
                                    ?
                                    <IconButton onClick={handleExpadedContent}>
                                        <ExpandLess />
                                    </IconButton>
                                    :
                                    <IconButton onClick={handleExpadedContent}>
                                        <ExpandMore />
                                    </IconButton>
                                }
                                <Typography variant="body1">
                                    {expandedContent && translation.translateKey(product, "components")}
                                </Typography>
                            </div>
                            {product.certificates && (
                                <div>
                                    <Typography variant="h6">
                                        {translation.language === "ua" && "Сертифікати"}
                                        {translation.language === "ru" && "Сертификаты"}

                                    </Typography>
                                    {expandedCertificate
                                        ?
                                        <IconButton onClick={handleExpadedCertificate}>
                                            <ExpandLess />
                                        </IconButton>
                                        :
                                        <IconButton onClick={handleExpadedCertificate}>
                                            <ExpandMore />
                                        </IconButton>
                                    }
                                    {expandedCertificate && <ProductCarousel picturesString={product.certificates} certificate={true} />}
                                </div>
                            )}

                        </div>
                    </div>
                )}
            </Container>
            <div className="Product--additional--options">
                {product && (
                    <BuyWidget
                        widgetProduct={true}
                        available={product.is_available}
                        mode="column"
                        product={product}
                        onClick={() => setSnackBarOpen(true)}
                    />
                )
                }
                <Button
                    style={{ margin: 20 }}
                    variant="contained"
                    onClick={() => setOpen(true)}
                    color="primary"
                    disableElevation
                >
                    {translation.language === "ua" && "Отримати додакову інформацію"}
                    {translation.language === "ru" && "Получить дополнительную информацию"}
                </Button>
            </div>
            <div className="Product--feedback">
                <div style={{ textAlign: "center" }}>
                    <Typography variant="h6">
                        {translation.language === "ua" && "Відгуки про товар"}
                        {translation.language === "ru" && "Отзывы о товаре"}
                    </Typography>
                </div>
                {showFeedbackDialog && (
                    <FeedbackDialog
                        user={userId}
                        translation={translation}
                        onClose={() => setShowFeedbackDialog(false)}
                        product_id={product.id}
                        onSend={m => setMessages(ms => ms.concat(m))}
                    />
                )}
                <div className="Product--feedback-btn">
                    <Button
                        onClick={() => setShowFeedbackDialog(true)}
                        variant="text"
                        color="primary"

                    >
                        <>
                            {translation.language === "ua" && "Залишити відгук"}
                            {translation.language === "ru" && "Оставить отзыв"}
                        </>

                    </Button>
                </div>
                <div className="Product--feedback-messages">
                    {messages.sort((a, b) => b.time - a.time).map(message => {
                        return (<div className="feedback-message" key={message.id}>
                            <div className="feedback-top">
                                <div>
                                    <p style={{ margin: 0 }}>{message.user_name}</p>
                                    <span>{new Date(message.time * 1000).toLocaleDateString()}</span>
                                </div>

                                <Rating name="read-only" value={message.rate} readOnly />
                            </div>
                            <div className="feedback-bottom">
                                <div className="feedback-text">
                                    <p>{message.text}</p>
                                </div>
                                {(userId.id === message.user_id ||
                                    (userId.id === 6 && userId.email === "vlad.yaromiy@gmail.com"))
                                    &&
                                    <div>
                                        <IconButton className="deleteForeverIcon">
                                            <DeleteIcon onClick={() => handleDeleteFeedback(message.id)} />
                                        </IconButton>
                                    </div>}
                            </div>
                        </div>)
                    })}
                </div>
                {messages.length !== 0 && <Button
                    className={"feedback--load"}
                    variant="text"
                    color="primary"
                    onClick={handleLoadMore}
                >
                    {translation.language === "ua" && "Завантажити ще"}
                    {translation.language === "ru" && "Загрузить еще"}
                </Button>}

            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        translation: state.translationReducer,
        isLoggedIn: state.sessionReducer.isLoggedIn,
        userId: state.sessionReducer.user
    }
}

export default connect(mapStateToProps, null)(Product)
