import React, { useEffect, useState, useCallback } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { Typography } from "@material-ui/core"
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import BuyWidget from "./BuyWidget"
import "./ProductCard.scss"

const ProductCard = ({ translation, product, onBuy }) => {
    const sizeTypes = {
        1: "мл",
        2: "мг",
        3: "г",
        4: "шт"
    }

    const [salePercent, setSalePercent] = useState(0)

    const handleSalePercent = useCallback(async () => {
        let result = await ((product.new_price / product.price) * 100).toFixed(2);
        setSalePercent(100 - result);
    }, [product.new_price, product.price])

    useEffect(() => {
        handleSalePercent()
    }, [handleSalePercent])


    return (
        <div key={product.id} className="Collection--item">
            {product.with_action === true && (
                < div className="Collection--item--sale">
                    <span style={{ fontSize: 19 }}>
                        {translation.language === "ua" && "Акція!"}
                        {translation.language === "ru" && "Акция!"}
                    </span>
                    <span style={{ fontSize: 15 }}>-{Math.round(salePercent).toLocaleString()}%</span>
                </div>
            )}
            {product.is_new === true && (
                < div className="Collection--item--sale new">
                    <span style={{ fontSize: 19 }}>
                        {translation.language === "ua" && "Новинка!"}
                        {translation.language === "ru" && "Новинка!"}
                    </span>
                </div>
            )}
            {product.is_gift === true && (
                < div className="Collection--item--gift">
                    <span>
                        <CardGiftcardIcon style={{ color: "#b5af00", fontSize: 55 }} />
                        <span className="Collection--item--gift_text">
                            {translation.language === "ua" && product.gift_ua}
                            {translation.language === "ru" && product.gift_ru}
                        </span>
                    </span>
                </div>
            )}
            <Link
                className="Collection--item--wrapper"
                to={"/products/" + product.id}
            >
                <div
                    className="Collection--item--image"
                    style={{
                        background:
                            "url(" +
                            process.env.REACT_APP_API +
                            "/media/" +
                            product.picture.split(",")[0] +
                            ")"
                    }}
                ></div>
                <Typography variant="h6">
                    {translation.translateKey(product, "name")}
                </Typography>
                <Typography variant="subtitle1" style={{ display: "flex", flexDirection: "column" }}>
                    {product.with_action === true
                        ?
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <p style={{ marginRight: 10, marginBottom: 0, fontWeight: 600 }}
                                className='sales_price'>{product.price.toLocaleString()} ГРН </p>
                            <p className="new_price" >{product.new_price.toLocaleString()} ГРН</p>
                        </div>
                        : <p style={{ fontWeight: 600 }}>{product.price.toLocaleString()} ГРН</p>}
                    <div>
                        <p>
                            {product.size.toLocaleString()} {sizeTypes[product.size_type]}
                            {product.additional_size !== 0 && <span><span> + </span> {product.additional_size} </span>}
                        </p>

                    </div>
                    {product.firm_ua &&
                        <p>
                            {translation.language === "ua" && product.firm_ua}
                            {translation.language === "ru" && product.firm_ru}
                        </p>
                    }
                </Typography>
            </Link>
            <BuyWidget available={product.is_available} product={product} onClick={onBuy} mode="card" />
        </div >
    )
}

const mapStateToProps = state => {
    return {
        translation: state.translationReducer
    }
}

export default connect(mapStateToProps)(ProductCard)
