import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'

import { connect } from "react-redux"

import { makeStyles } from '@material-ui/core/styles'
import {
    Modal,
    Paper,
    InputBase,
    Typography,
    Divider,
    IconButton,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'

import './SearchDialog.css'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '6px 8px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        backgroundColor: 'white',
        borderRadius: '0',
    },
    input: {
        marginLeft: theme.spacing(1),
        marginBottom: 0,
        flex: 1,
        color: 'black',
        fontFamily: '"Roboto", Helvetica',
    },
}))

const SearchDialog = (props) => {
    const { translation } = props

    const [query, setQuery] = useState('')
    const [products, setProducts] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const classes = useStyles()

    const history = useHistory()

    useEffect(() => {
        setIsLoading(true)

        axios('/search/?query=' + query)
            .then((res) => {
                setProducts(res.data)
            })
            .finally(setIsLoading(false))
    }, [query])

    const handleSelectProduct = (e, p) => {
        history.push('/products/' + p.id)
        props.onClose(e)
    }

    const searchLabel = {
        "search_ua": "Пошук...",
        "search_ru": "Поиск..."
    }

    return (
        <Modal open={props.open}>
            <div className="SearchDialog">
                <Paper component="form" className={classes.root}>
                    <InputBase
                        className={classes.input}
                        placeholder={translation.translateKey(searchLabel, "search")}
                        autoFocus
                        inputProps={{ 'aria-label': 'search products' }}
                        onChange={(e) => setQuery(e.target.value)}
                    />
                    <Divider className={classes.divider} orientation="vertical" />
                    <IconButton
                        className={classes.iconButton}
                        onClick={props.onClose}
                        aria-label="close"
                    >
                        <Close />
                    </IconButton>
                </Paper>

                <div className="SearchDialog--results">
                    {!isLoading &&
                        products.map((p) => {
                            return (
                                <Paper
                                    key={p.id}
                                    onClick={(e) => handleSelectProduct(e, p)}
                                    className="SearchDialog--item"
                                >
                                    <div style={{
                                        height: 75,
                                        width: 75,
                                        background: "url('" + process.env.REACT_APP_API + '/media/' + p.picture.split(",")[0] + "')",
                                        backgroundSize: "cover"
                                    }} />
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <Typography variant="button" style={{
                                            fontFamily: 'Roboto Condensed',
                                            fontSize: 16,
                                            paddingLeft: 10,
                                            paddingTop: 5
                                        }}>{translation.translateKey(p, "name")}</Typography>
                                        <Typography variant="caption" style={{
                                            fontSize: 14,
                                            fontWeight: "bold",
                                            fontFamily: 'Roboto',
                                            paddingLeft: 10,
                                            paddingTop: 12.5,
                                        }}>{p.with_action ? p.new_price : p.price} ГРН</Typography>
                                    </div>
                                </Paper>
                            )
                        })}
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    return {
        translation: state.translationReducer
    }
}

export default connect(mapStateToProps)(SearchDialog)