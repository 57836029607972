import React, { useState } from "react"
import { connect } from "react-redux"
import * as actions from "./../store/actions"

import axios from "axios"

import { Button, IconButton } from "@material-ui/core"
import { Add, Remove } from "@material-ui/icons"

import "./BuyWidget.css"

const randomId = () => {
  return window.crypto.getRandomValues(new Uint32Array(1))[0]
}

const BuyWidget = props => {
  const [counter, setCounter] = useState(1)

  const { translation, product, isLoggedIn, onClick, mode, storeBasket, available, widgetProduct } = props

  const inc = e => {
    e.preventDefault()
    if (counter < 20) {
      setCounter(c => c + 1)
    }
  }
  const dec = e => {
    e.preventDefault()
    if (counter > 1) {
      setCounter(c => c - 1)
    }
  }

  const handleBuyClick = e => {
    e.stopPropagation()

    // Basket
    const data = new FormData()
    data.append(
      "basketItem",
      JSON.stringify({
        product: product.id,
        quantity: counter
      })
    )

    if (isLoggedIn) {
      axios
        .post(`/basket/store/`, data, {
          withCredentials: true
        })
        .then(res => {
          console.log("[BuyWidget::StoreBasket]: ", res.data)
          storeBasket({
            product: product,
            info: {
              quantity: counter,
              product_price: product.price,
              id: res.data.id // Responds with id
            }
          })
        })
        .catch(err => {
          console.log("[BuyWidget::Error]: ", err)
        })
    } else {
      storeBasket({
        product: product,
        info: {
          quantity: counter,
          product_price: product.price,
          id: randomId() // Responds with id
        }
      })
    }

    if (onClick) {
      onClick()
    }
  }

  let widget = null

  if (mode === "card") {
    widget = (
      <div className={"BuyWidget BuyWidget--card"}>
        <div className="BuyWidget--buttons">
          <IconButton onClick={dec} disabled={available ? false : true}>
            <Remove />
          </IconButton>
          <span>{counter}</span>
          <IconButton onClick={inc} disabled={available ? false : true}>
            <Add />
          </IconButton>
        </div>
        <Button
          disabled={available ? false : true}
          onClick={handleBuyClick}
          color="primary"
          variant="contained"
          className="BuyWidget--btn"
          disableElevation
        >
          {available ? translation.language === "ua" && "Придбати" : translation.language === "ua" && "Недоступний"}
          {available ? translation.language === "ru" && "Купить" : translation.language === "ru" && "Недоступен"}
        </Button>
      </div>
    )
  }
  if (mode === "column") {
    widget = (
      <div className={widgetProduct ? "BuyWidget BuyWidget--column expired" : "BuyWidget BuyWidget--column"}>
        <div className="BuyWidget--buttons">
          <IconButton onClick={dec} disabled={available ? false : true}>
            <Remove />
          </IconButton>
          <span>{counter}</span>
          <IconButton onClick={inc} disabled={available ? false : true}>
            <Add />
          </IconButton>
        </div>
        <Button
          disabled={available ? false : true}
          onClick={handleBuyClick}
          color="primary"
          size="large"
          fullWidth
          variant="contained"
          className="BuyWidget--btn"
          disableElevation
        >
          {available ? translation.language === "ua" && "Придбати" : translation.language === "ua" && "Недоступний"}
          {available ? translation.language === "ru" && "Купить" : translation.language === "ru" && "Недоступен"}
        </Button>
      </div>
    )
  }

  return widget
}

const mapStateToProps = state => {
  return {
    translation: state.translationReducer,
    isLoggedIn: state.sessionReducer.isLoggedIn
  }
}

const mapDispatchToProps = dispatch => {
  return {
    storeBasket: basketItem =>
      dispatch({ type: actions.STORE_BASKET, payload: basketItem })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BuyWidget)
