import React, { useEffect, useState } from "react"
import Autocomplete from "@material-ui/lab/Autocomplete"
import TextField from "@material-ui/core/TextField"
import axios from "axios"
import { connect } from "react-redux"

const SearchDepartmentAutocomplete = props => {
  const [value, setValue] = useState(props.value)
  const [department, setDepartment] = useState('')
  const [options, setOptions] = useState([])

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  const { city } = props
  useEffect(() => {
    axios(`/np/find_department/?city=${city}&query=${department}`).then(res => {
      setOptions(res.data)
    })
  }, [city, department])

  const handleDepartmentChange = e => setDepartment(e.target.value)

  return (
    <div style={{ width: "100%" }}>
      <Autocomplete
        value={value}
        options={options}
        autoHighlight
        filterSelectedOptions
        getOptionLabel={o => o.description_ua}
        getOptionSelected={(o, v) => o.id === v.id}
        onChange={(e, department) => {
          if (department) {
            setValue(value)
            props.onChange(department)
          }
        }}
        renderInput={renderParams => (
          <TextField {...renderParams} onChange={handleDepartmentChange} helperText={props.translation.language === "ua" ? "Почніть вводити відділення нової пошти" : "Начните вводить отделение новой почты"} margin="dense" label="Відділення" fullWidth />
        )}
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    translation: state.translationReducer,
  }
}

export default connect(mapStateToProps, null)(SearchDepartmentAutocomplete)