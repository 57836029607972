import React, { useState } from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import InstagramIcon from "@material-ui/icons/Instagram"
import FacebookIcon from "@material-ui/icons/Facebook"
import Container from "./Container"

import {
  Button,
} from "@material-ui/core"

import "./Footer.scss"
import DialogCall from "./DialogCall"

const Footer = ({ translation }) => {
  const [open, setOpen] = useState(false)

  return (
    <div className="footer-wrapper">
      <DialogCall pr={true} open={open} setOpen={setOpen} />
      <Container>
        <div className="footer">
          <div className="footer-top">
            <div className="support fcol">
              <span className="footer__title">
                {translation.language === "ua" && "Інформація"}
                {translation.language === "ru" && "Информация"}
              </span>
              <div className="footer__list">
                {translation.language === "ua" && (
                  <>
                    <p>
                      <Link to="/contacts">Контакти</Link>
                    </p>
                    <p>
                      <Link to="/faq">FAQ</Link>
                    </p>
                    <p>
                      <Link to="/about-us">Про нас</Link>
                    </p>
                    <p>
                      <Link to="/support">Підтримка</Link>
                    </p>
                    <p>
                      <Link to="/publicOfferAgreement">Договір публічної оферти</Link>
                    </p>
                    <p>
                      <Link to="/privacyPolicy">Політика конфіденційності</Link>
                    </p>
                  </>
                )}
                {translation.language === "ru" && (
                  <>
                    <p>
                      <Link to="/contacts">Контакты</Link>
                    </p>
                    <p>
                      <Link to="/faq">FAQ</Link>
                    </p>
                    <p>
                      <Link to="/about-us">О нас</Link>
                    </p>
                    <p>
                      <Link to="/support">Поддержка</Link>
                    </p>
                  </>
                )}
              </div>
            </div>

            <div className="socials fcol">
              <span className="footer__title">
                {translation.language === "ua" && "Ми в соцмережах"}
                {translation.language === "ru" && "Мы в соцсетях"}
              </span>
              <div className="footer__list">
                <a
                  href="https://www.instagram.com/yva_international/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <InstagramIcon
                    className="instagram"
                    style={{ fontSize: 32 }}
                  />
                </a>
                <a
                  href="https://www.facebook.com/yvainternational/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FacebookIcon className="facebook" style={{ fontSize: 32 }} />
                </a>
              </div>
            </div>
            <div className="signUp fcol">
              <Button
                variant="contained"
                onClick={() => setOpen(true)}
                color="primary"
                disableElevation
              >
                {translation.language === "ua" && "Зворотній зв'язок"}
                {translation.language === "ru" && "Обратная связь"}
              </Button>
            </div>
          </div>
          <div>
            <div className="rights fcol">
              <p>
                © YVA<sup>®</sup> Natural Products, Inc.&nbsp; |&nbsp;
                Chernivtsi, Ukraine&nbsp; &nbsp;|&nbsp;&nbsp;
              </p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    translation: state.translationReducer,
  }
}

export default connect(mapStateToProps)(Footer)
