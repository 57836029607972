import Loader from 'react-loader-spinner'
import React from "react"

const LoaderSpinner = ({ visible }) => {
    return (
        <Loader
            visible={visible}
            type="Oval"
            color="#009688"
            height={70}
            width={70}
            timeout={100000} //3 secs

        />
    );
}

export default LoaderSpinner