import React, { useState, useEffect } from "react"

import { MenuItem, Button, Typography, Snackbar } from "@material-ui/core"

import Container from "../../ui/Container"

import Menu from "../../ui/Menu"
import ProductCard from "../../ui/ProductCard"

const sortString = {
  "descending": {
    "ua": "За ціною (спочатку дорожчі)",
    "ru": "За ценой (сначала дороже)",
  },
  "ascending": {
    "ua": "За ціною (спочатку дешевші)",
    "ru": "За ценой (сначала дешевле)"
  },
  "alphabetical": {
    "ua": "За назвою",
    "ru": "По названию"
  }
}

const CollectionBlock = ({ collection, translation, sort, setSort }) => {
  const [products, setProducts] = useState(collection.products)
  const { filters, category } = collection

  useEffect(() => {
    setProducts(collection.products)
  }, [collection])

  const [filterIndex, setFilterIndex] = useState(-1)
  const [menuAnchor, setMenuAnchor] = useState(null)
  const [filterAnchor, setFilterAnchor] = useState(null)
  const [snackBarOpen, setSnackBarOpen] = useState(false)

  const applySort = s => {
    setSort(s)
    setMenuAnchor(null)
  }

  const applyFilter = index => {
    setFilterIndex(index)
    setFilterAnchor(null)
  }

  const getProducts = () => {
    if (filterIndex === -1) {
      return (
        <div className="Collection--items">
          {products.map(product => (
            <ProductCard
              key={product.id}
              translation={translation}
              product={product}
              onBuy={() => setSnackBarOpen(true)}
            />
          ))}
        </div>
      )
    } else {
      let filtered = []
      if (filters.length !== 0) {
        filtered = products.filter(
          product => product.filter === filters[filterIndex].id
        )
      }

      if (filtered.length !== 0) {
        return (
          <div className="Collection--items">
            {filtered.map(product => (
              <ProductCard
                key={product.id}
                translation={translation}
                product={product}
                onBuy={() => setSnackBarOpen(true)}
              />
            ))}
          </div>
        )
      } else {
        return (
          <Typography
            variant="h5"
            style={{
              textAlign: "center",
              paddingTop: "100px",
              paddingBottom: "100px"
            }}
          >
            {translation.language === "ua"
              ? "Жоден товар не відповідає заданому фільтру"
              : "Ни один товар не соотвествует заданному фильтру"}
          </Typography>
        )
      }
    }
  }

  return (
    <div id={category.url} className="Collection--category">
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={snackBarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackBarOpen(false)}
        message="Товар добавлено в корзину"
      />
      <div className="Collection--category--info">
        <h2>{translation.translateKey(category, "name")}</h2>
        <Container>
          <p>{translation.translateKey(category, "short")}</p>
        </Container>
      </div>
      <Container>
        <div className="Collection--category--actions">
          <div className="Collection--category--action Collection--category--sort">
            <p className="Collection--category--filter--name">
              {translation.language === "ua" && "Сортування"}
              {translation.language === "ru" && "Сортировка"}
            </p>
            <Button
              variant="outlined"
              color="primary"
              onClick={e => setMenuAnchor(e.target)}
              style={{ marginRight: "10px" }}
            >
              {sortString[sort][translation.language]}
            </Button>
            <Menu
              anchorEl={menuAnchor}
              open={Boolean(menuAnchor)}
              onClose={() => setMenuAnchor(null)}
            >
              <MenuItem onClick={() => applySort("descending")}>
                {sortString["descending"][translation.language]}
              </MenuItem>
              <MenuItem onClick={() => applySort("ascending")}>
                {sortString["ascending"][translation.language]}
              </MenuItem>
              <MenuItem onClick={() => applySort("alphabetical")}>
                {sortString["alphabetical"][translation.language]}
              </MenuItem>
            </Menu>
          </div>
          <div className="Collection--category--action Collection--category--filter">
            {filters.length !== 0 && (
              <>
                <p className="Collection--category--filter--name">
                  {translation.language === "ua" && "Фільтр"}
                  {translation.language === "ru" && "Фильтр"}
                </p>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={e => setFilterAnchor(e.target)}
                >
                  {filterIndex !== -1 &&
                    translation.translateKey(filters[filterIndex], "name")}
                  {filterIndex === -1
                    ? translation.language === "ua"
                      ? "Всі"
                      : "Все"
                    : null}
                </Button>
              </>
            )}
            <Menu
              anchorEl={filterAnchor}
              open={Boolean(filterAnchor)}
              onClose={() => setFilterAnchor(null)}
            >
              <MenuItem
                onClick={() => {
                  applyFilter(-1)
                }}
              >
                {translation.language === "ua" ? "Всі" : "Все"}
              </MenuItem>
              {filters.map((filter, index) => {
                return (
                  <MenuItem
                    key={filter.id}
                    onClick={() => {
                      applyFilter(index)
                    }}
                  >
                    {translation.translateKey(filter, "name")}
                  </MenuItem>
                )
              })}
            </Menu>
          </div>
        </div>
        {getProducts()}
      </Container>
    </div>
  )
}

export default CollectionBlock
