import React from "react"
import Typography from "@material-ui/core/Typography"
import "./HomeSlider.css"
import { connect } from "react-redux"

const HomeCategory = ({ translation }) => {
  return (
    <div className="HomeCategory">
      <div className="Home--video">
        <Typography variant="h5">
          {translation.language === "ua" ? "Відео з нашого ютуб каналу" : "Видео с нашего ютуб канала"}
        </Typography>
        <div className="youtube--videos">
          <iframe
            title="Dominion video"
            height="413"
            src="https://www.youtube.com/embed/Knv69NILZPo"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <iframe
            title="Love Potion video"
            height="413"
            src="https://www.youtube.com/embed/bSsQPeHvczU"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen>
          </iframe>
          <iframe
            title="Monarque video"
            height="413"
            src="https://www.youtube.com/embed/lFYUCe2fu3I"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen>
          </iframe>
          <Typography variant="h5">
            {translation.language === "ua"
              ? "Компанія  Ява-інтернешенл  є  офіційним імпортером продукції компанії Orihiro (Японія) в Україну."
              : "Компания Ява-интернешенл является официальным импортером продукции компании Orihiro (Япония) в Украине"}
          </Typography>
          <iframe
            title="Orihiro 1 video"
            height="413"
            src="https://www.youtube.com/embed/gyzE6Djicug"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen>
          </iframe>
          <iframe
            title="Orihiro 2 video"
            height="413"
            src="https://www.youtube.com/embed/KiFDy2sS7Ho"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen>
          </iframe>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    translation: state.translationReducer,
  }
}

export default connect(mapStateToProps)(HomeCategory)
