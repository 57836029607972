import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import { HashLink } from "react-router-hash-link";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const HomeSlider = ({ translation }) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    axios("/home_slider/").then((res) => {
      setItems(res.data);
      console.log("DATA:", res.data);
    });
  }, []);

  return (
    <div className="HomeSlider">
      <Carousel
        showArrows={true}
        autoPlay={true}
        swipeable={true}
        infiniteLoop={true}
        showStatus={true}
        showIndicators={true}
        stopOnHover={true}
        showThumbs={false}
        dynamicHeight={true}
        interval={3000}
        transitionTime={450}
        swipeScrollTolerance={5}
        selectedItem={0}
      >
        {items.map((slide, idx) => {
          return (
            <div className="HomeSlider--item">
              <img className="HomeSlider--image" alt={translation.translateKey("slide", "text")} src={`${process.env.REACT_APP_API}/media/${slide.picture}`} />
              <div className="legend HomeSlider--item--info">
                <div className="HomeSlider--item--background">
                  <Typography variant="h4">
                    {translation.translateKey(slide, "text")}
                  </Typography>
                  <HashLink to={"/collections/" + slide.url.split("#").join("/")}>
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                    >
                      {translation.language === "ua"
                        ? "Переглянути пропозицію"
                        : "Посмотреть предложение"}
                    </Button>
                  </HashLink>
                </div>
              </div>
            </div>
          )
        })}
      </Carousel>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    translation: state.translationReducer,
  };
};

export default connect(mapStateToProps)(HomeSlider);
