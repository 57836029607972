import React from "react"
import { connect } from "react-redux"

import Header from "../ui/Header"
import Container from "../ui/Container"
import PageTitle from "../ui/PageTitle"
import { Typography, Paper } from "@material-ui/core"

const PrivacyPolicy = ({ translation }) => {
    return (
        <div className="PrivacyPolicy">
            <Header />
            <PageTitle title="Політика конфіденційності" />
            <Container>
                <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>


                    <Typography style={{ margin: '15px', fontWeight: "bold" }} variant="h6">Політика конфіденційності</Typography>

                    <Typography style={{ margin: '15px' }} variant="body1">
                        Ця Політика конфіденційності персональних даних (далі - Політика конфіденційності) діє відносно
                        всієї інформації, яку <span style={{ fontWeight: "bold" }}>Адміністратор сайту</span> https://yva.com.ua – <span style={{ fontWeight: "bold" }}>ТОВ «Ява-інтерншенл»</span> та
                        <span style={{ fontWeight: "bold" }}>Продавець</span> товару пропонованого на сайті https://yva.com.ua - <span style={{ fontWeight: "bold" }}>ФОП Метлушан В.І.</span>, може отримати
                        про Користувача під час використання сайту https://yva.com.ua (надалі – Сайт) та розміщення
                        замовлення товару.
                    </Typography>

                    <Typography style={{ margin: '15px', fontWeight: "bold" }} variant="body1">
                        1. Визначення термінів<br />
                    </Typography>
                    <Typography style={{ margin: '15px' }} variant="body1">
                        1.1 У цій Політиці конфіденційності використовуються такі терміни:<br />
                    </Typography>


                    <Typography style={{ margin: '15px' }} variant="body1">1.1.1. « <span style={{ fontWeight: "bold" }}>Адміністратор сайту</span> https://yva.com.ua (надалі – Адміністратор сайту)» - уповноважені
                        співробітники на управління сайтом, що діють від імені ТОВ «Ява-інтернешенл», які організовують і
                        (або) здійснюють обробку персональних даних, а також визначають цілі обробки персональних
                        даних, склад персональних даних, що підлягають обробці, дії (операції), що здійснюються з
                        персональними даними.</Typography>
                    <Typography style={{ margin: '15px' }} variant="body1">1.1.2. « <span style={{ fontWeight: "bold" }}>Продавець</span> товару пропонованого на сайті https://yva.com.ua (надалі – Продавець) -
                        ФОП Метлушан В.І., що здійснює роздрібний продаж товару представленого на Сайті</Typography>

                    <Typography style={{ margin: '15px' }} variant="body1">1.1.3. «<span style={{ fontWeight: "bold" }}>Персональні дані</span>» - будь-яка інформація, що стосується прямо, або побічно фізичної особи,
                        яка розміщує замовлення на придбання товару представленого на Сайті.</Typography>
                    <Typography style={{ margin: '15px' }} variant="body1">1.1.4. «<span style={{ fontWeight: "bold" }}>Обробка персональних даних»</span> - будь-яка дія (операція), або сукупність дій (операцій), що
                        здійснюються з використанням засобів автоматизації, або без використання таких засобів з
                        персональними даними, включаючи збір, запис, систематизацію, накопичення, зберігання, уточнення
                        (оновлення, зміна), витяг, використання, передачу (поширення, надання, доступ), знеособлення,
                        блокування, видалення, знищення персональних даних.</Typography>
                    <Typography style={{ margin: '15px' }} variant="body1">1.1.5. «<span style={{ fontWeight: "bold" }}>Конфіденційність персональних даних</span>» - обов&#39;язкова для дотримання Адміністратором
                        сайту/Продавцем вимога не допускати їх поширення без згоди суб&#39;єкта персональних даних, або
                        наявності іншої законної підстави.</Typography>
                    <Typography style={{ margin: '15px' }} variant="body1">1.1.6. «Користувач сайту (далі  Користувач)» - особа, яка має доступ до сайту, за допомогою мережі
                        Інтернет та використовує сайт.</Typography>
                    <Typography variant="body1" style={{ margin: '15px', fontWeight: "bold" }}>2. Загальні положення</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}>2.1. Використання Користувачем сайту означає згоду з цією Політикою конфіденційності та умовами
                        обробки персональних даних Користувача.</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}>2.2. У разі незгоди з умовами Політики конфіденційності Користувач повинен припинити
                        використання сайту.</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}>2.3. Дана Політика конфіденційності застосовується лише до сайту https://yva.com.ua.</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}>2.4. Адміністратор сайту не перевіряє достовірність персональних даних, що надаються
                        Користувачем сайту.</Typography>
                    <Typography variant="body1" style={{ margin: '15px', fontWeight: "bold" }}>3. Предмет політики конфіденційності</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}>3.1. Ця Політика конфіденційності встановлює зобов&#39;язання Адміністратора сайту щодо
                        нерозголошення та забезпечення режиму захисту конфіденційності персональних даних, які
                        Користувач надає Адміністратору сайту при оформленні замовлення на сайті.</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}>3.2. Персональні дані, дозволені до обробки в рамках цієї Політики конфіденційності, надаються
                        Користувачем при оформленні замовлення і включають в себе наступну інформацію:</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}>3.2.1. прізвище, ім&#39;я, по батькові Користувача;</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}>3.2.2. контактний телефон Користувача;</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}>3.2.3. адреса електронної пошти (e-mail);</Typography>
                    <Typography variant="body1" style={{ margin: '15px', fontWeight: "bold" }}> 4. Цілі збору персональної інформації Користувача</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}>  4.1. Персональні дані Користувача Адміністратор сайту може використовувати в цілях:</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}>  4.1.1. Ідентифікації Користувача, для оформлення замовлення і (або) укладення Договору купівлі-
                        продажу товару дистанційним способом з Продавцем.</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}>  4.1.2. Встановлення з Користувачем зворотного зв&#39;язку, включаючи напрямок повідомлень, обробка
                        запитів і заявок від Користувача.</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}>  4.1.3. Підтвердження достовірності та повноти персональних даних, наданих Користувачем.</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}>  4.1.4. Повідомлення Користувача сайту про стан Замовлення, обробки і отримання платежів.</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}>  4.1.5. Надання Користувачеві ефективної технічної підтримки при виникненні проблем пов&#39;язаних з
                        використанням сайту.</Typography>

                    <Typography variant="body1" style={{ margin: '15px' }}> 4.1.6. Надання Користувачеві з його згоди, оновлень продукції, спеціальних пропозицій, інформації
                        про ціни, розсилки новин та інших відомостей від імені сайту.</Typography>
                    <Typography variant="body1" style={{ margin: '15px', fontWeight: "bold" }}>  5. Способи і терміни обробки персональної інформації</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}> 5.1. Розкриття персональних даних відбувається при замовленні Користувачем товарів на Сайті в
                        об’ємі необхідному для ідентифікації Користувача, виконання та опрацювання замовлення
                        Користувача.</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}>  5.2. Користувач погоджується з тим, що Адміністратор сайту має право передавати персональні дані
                        Продавцю товару представленого на сайті, третім особам, зокрема, кур&#39;єрським службам,
                        організаціями поштового зв&#39;язку, виключно з метою виконання замовлення Користувача,
                        оформленого на сайті включаючи доставку Товару.</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}>  5.3. Персональні дані Користувача можуть бути передані уповноваженим органам державної влади
                        України тільки на підставах та в порядку, встановленим законодавством України</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}>  5.4. При втраті або розголошенні персональних даних Адміністратор сайту інформує Користувача
                        про втрату або розголошення персональних даних.</Typography>
                    <Typography variant="body1" style={{ margin: '15px', fontWeight: "bold" }}>  6. Зобов&#39;язання сторін</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}>  6.1. Користувач зобов&#39;язаний:</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}>  6.1.1. Надати інформацію про персональні дані, необхідну для розміщення замовлення на Сайті та
                        опрацювання його Продавцем.</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}>  6.1.2. Оновити, доповнити надану інформацію про персональні дані в разі зміни даної інформації.</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}>  6.2. Адміністратор сайту та Продавець зобов&#39;язані:</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}>  6.2.1. Використовувати отриману інформацію виключно для цілей, зазначених у п. 4 цієї Політики
                        конфіденційності.</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}>  6.2.2. Забезпечити зберігання конфіденційної інформації в таємниці, не розголошувати без
                        попередньої письмової згоди Користувача, а також не здійснювати продаж, обмін, опублікування, або
                        розголошення іншими можливими способами переданих персональних даних Користувача, за
                        винятком п.п. 5.2. і 5.3. цієї Політики Конфіденційності.</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}> 6.2.3. Вживати заходів обережності для захисту конфіденційності персональних даних Користувача
                        згідно з порядком, який зазвичай використовується для захисту такого роду інформації в існуючому
                        діловому обороті.</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}>  6.2.4. Здійснити блокування персональних даних, що відносяться до відповідного Користувача, з
                        моменту звернення або запиту Користувача або його законного представника або уповноваженого
                        органу з захисту прав суб&#39;єктів персональних даних на період перевірки, в разі виявлення
                        недостовірних персональних даних або неправомірних дій.</Typography>
                    <Typography variant="body1" style={{ margin: '15px', fontWeight: "bold" }}>  7. Відповідальність сторін</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}>  7.1. Адміністратор сайту/Продавець, який не виконав свої зобов&#39;язання, несе відповідальність за
                        збитки, понесені Користувачем у зв&#39;язку з неправомірного використанням персональних даних,
                        відповідно до законодавства України, за винятком випадків, передбачених п.п. 5.2., 5.3. і 7.2. цієї
                        Політики Конфіденційності.</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}> 7.2. У разі втрати або розголошення Конфіденційної інформації Адміністратор сайту/Продавець не
                        несе відповідальність, якщо дана конфіденційна інформація:</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}> 7.2.1. Стала публічним надбанням до її втрати або розголошення.</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}> 7.2.2. Була отримана від третьої сторони до моменту її отримання Адміністратором сайту/Продавцем.</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}> 7.2.3. Була розголошена за згодою Користувача.</Typography>
                    <Typography variant="body1" style={{ margin: '15px', fontWeight: "bold" }}> 8. Вирішення спорів</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}> 8.1. До звернення в суд з позовом у спорах, що виникають із відносин між Користувачем сайту і
                        Адміністратором сайту/Продавцем, обов&#39;язковим є пред&#39;явлення претензії (письмової пропозиції про
                        добровільне врегулювання спору).</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}> 8.2. Отримувач претензії протягом 30 календарних днів з дня отримання претензії, письмово
                        повідомляє заявника претензії про результати розгляду претензії.</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}> 8.3. При не досягненні угоди суперечка буде передана на розгляд до судового органу відповідно до
                        чинного законодавства України.</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}> 8.4. До цієї Політики конфіденційності та відносин між Користувачем і Адміністратором
                        сайту/Продавцем застосовується чинне законодавство України.</Typography>

                    <Typography variant="body1" style={{ margin: '15px', fontWeight: "bold" }}> 9. Додаткові умови</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}> 9.1. Адміністратор сайту має право вносити зміни в дану Політику конфіденційності без згоди
                        Користувача.</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}> 9.2. Нова Політика конфіденційності вступає в силу з моменту її розміщення на cайті, якщо інше не
                        передбачено новою редакцією Політики конфіденційності.</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}> 9.3. Всі пропозиції або питання по цій Політиці конфіденційності слід повідомляти за контактами,
                        вказаними за адресою:  manager@yva.com.ua</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}> 9.4. Діюча Політика конфіденційності розміщена на сторінці за адресою  https://yva.com.ua</Typography>
                </Paper>
            </Container>
        </div >
    )
}

const mapStateToProps = state => {
    return {
        translation: state.translationReducer
    }
}

export default connect(mapStateToProps)(PrivacyPolicy)
