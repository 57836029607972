import React from "react"
import { connect } from "react-redux"

import Header from "../ui/Header"
import Container from "../ui/Container"
import PageTitle from "../ui/PageTitle"
import { Typography, Paper } from "@material-ui/core"

const PublicOfferAgreement = ({ translation }) => {
    return (
        <div className="PublicOfferAgreement">
            <Header />
            <PageTitle title="Договір публічної оферти" />
            <Container>
                <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>


                    <Typography style={{ margin: '15px', fontWeight: "bold" }} variant="h6">Істотні умови договору публічної оферти</Typography>

                    <Typography style={{ margin: '15px' }} variant="body1">
                        Даний Договір є публічною офертою в розумінні положень чинного законодавства України.
                        Викладений нижче текст Договору публічної оферти (надалі – «Договір»), адресований
                        невизначеному колу осіб, відвідувачам сайту: https://yva.com.ua (надалі – Сайт).
                    </Typography>

                    <Typography style={{ margin: '15px' }} variant="body1">
                        Визначення трактуються виходячи з їх сутності та змісту цього договору. Нижче наведено список
                        цих визначень:<br />
                        <span style={{ fontWeight: "bold" }}>Адміністратор сайту</span> https://yva.com.ua – ТОВ «Ява-інтерншенл», що здійснює лише гуртовий
                        продаж товару представленого на Сайті.<br />
                        <span style={{ fontWeight: "bold" }}>Продавець</span> товару пропонованого на сайті https://yva.com.ua - ФОП Метлушан В.І., що здійснює
                        роздрібний продаж товару представленого на Сайті.<br />
                        <span style={{ fontWeight: "bold" }}>Покупець</span> - будь-яка дієздатна фізична, юридична особа, фізична особа-підприємець, згідно чинного
                        українського законодавства, яка відвідала сайт https://yva.com.ua, що має намір зробити на сайті
                        замовлення Товару.<br />
                        <span style={{ fontWeight: "bold" }}>Товар</span> - об&#39;єкт угоди сторін, який був обраний покупцем і поміщений в кошик на Сайті.
                    </Typography>


                    <Typography style={{ margin: '15px', fontWeight: "bold" }} variant="body1">2. Загальні положення </Typography>
                    <Typography style={{ margin: '15px' }} variant="body1">  2.1 Даний Договір визначає особливості купівлі-продажу замовленого товару на Сайті.</Typography>

                    <Typography style={{ margin: '15px', fontWeight: "bold" }} variant="body1">3. Предмет договору публічної оферти</Typography>
                    <Typography style={{ margin: '15px' }} variant="body1"> 3.1. Придбання товару в порядку, визначеному цим договором, за цінами, вказаними на Сайті та згода
                        з умовами оплати і доставки товару.</Typography>
                    <Typography style={{ margin: '15px', fontWeight: "bold" }} variant="body1"> 4. Момент укладання договору</Typography>
                    <Typography style={{ margin: '15px' }} variant="body1"> 4.1 Текст даного Договору є публічною офертою і діє для всіх відвідувачів Сайту, які мають намір,
                        бажання і можливості придбати товари.</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}>   4.2 Акцепт оферти - придбання товару в порядку, визначеному цим договором, за цінами, вказаними
                        на Сайті та згода з умовами оплати і доставки товару.</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}> 4.3 Факт розміщення замовлення Товару є беззаперечним прийняттям Покупцем умов даного
                        Договору. Покупець, який скористався послугами Сайту, розглядається як особа, яка вступила з
                        Продавцем в договірні відносини.</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}>   4.4 Інформація про Товари представлена ​​на Сайті.</Typography>
                    <Typography variant="body1" style={{ margin: '15px', fontWeight: "bold" }}> 5. Права і обов&#39;язки сторін</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}>  5.1 Продавець зобов&#39;язується передати Покупцеві товар: в певному місці та в певній кількості,
                        належної якості, вільним від претензій третіх осіб.</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}> 5.1.1 З моменту укладення цього Договору забезпечити в повній мірі всі зобов&#39;язання перед
                        Покупцем відповідно до умов передбачених на Сайті та цим договором.</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}>  5.1.2 Продавець зобов&#39;язується не розголошувати будь-яку приватну інформацію про Покупця і не
                        надавати доступ до цієї інформації третім особам, за винятком випадків, передбачених
                        законодавством, або за бажанням самого Покупця.</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}>   5.1.3 Продавець залишає за собою право невиконання зобов&#39;язань, в разі виникнення форс-мажорних
                        ситуацій.</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}> 5.2 Продавець має право:</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}>   5.2.1 Змінювати умови цього Договору в односторонньому порядку, розміщуючи їх на Сайті. Всі
                        зміни вступають в силу негайно після публікації.</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}>  5.3 Покупець зобов&#39;язується:</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}>   5.3.1 До моменту укладення Договору ознайомитися зі змістом Договору, умовами Договору і
                        цінами, пропонованими на Сайті.</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}>            5.3.2 На виконання Продавцем своїх зобов&#39;язань перед Покупцем останній повинен повідомити всі
                        необхідні дані, що  однозначно ідентифікують його як Покупця, і достатні для доставки Покупцю
                        сплаченого ним Товару.</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}>   5.4. Покупець має право отримати всю необхідну інформацію про якість та безпеку використання
                        товару, який він замовляє.</Typography>

                    <Typography variant="body1" style={{ margin: '15px', fontWeight: "bold" }}>   6. Форс-мажорні обставини</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}> 6.1 Сторони звільняються від відповідальності за невиконання, або неналежне виконання зобов&#39;язань
                        за Договором на час дії «непереборної сили». Під «непереборною силою» розуміються надзвичайні і
                        непереборні за даних умов обставини, що перешкоджають виконанню своїх зобов&#39;язань сторонами за
                        цим Договором. До них відносяться стихійні явища (землетруси, повені і т.п.), обставини суспільного
                        життя (воєнні дії, надзвичайні стани, найбільші страйки, епідемії і т.п.), Заборонні заходи державних
                        органів. Протягом цього часу сторони не мають взаємних претензій, і кожна зі сторін приймає на себе
                        ризик наслідків форс-мажорних обставин.</Typography>
                    <Typography variant="body1" style={{ margin: '15px', fontWeight: "bold" }}> 7. Доставка товару</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}>   7.2. При доставці товарів в інші міста України, виконуваної перевізником Нова пошта (далі – Нова
                        пошта), Покупець в повному обсязі і беззастережно погоджується з правилами перевезення вантажів,
                        які доступні на сайті Нової пошти.</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}> 7.3. Факт отримання товару і відсутність претензій до якості товару Покупця підтверджується фактом
                        відображення на сайті Нової пошти інформації про отримання відправлення. Зі свого боку,
                        Продавець гарантує, за умови наявності товару, відвантаження його Новою поштою в кількості,
                        зазначеній в замовленні Покупця, в належному  стані та якості.</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}> 7.4 У разі відмови Покупця від отримання товару в пункті видачі Нової пошти, товар повертається
                        Продавцю. Оплата за послуги Нової пошти стягується з суми, перерахованої Покупцем за товар
                        Продавцю. Залишок суми повертається Покупцю на підставі його листа, із зазначенням
                        розрахункового рахунку, на який повинні бути повернуті кошти.</Typography>
                    <Typography variant="body1" style={{ margin: '15px', fontWeight: "bold" }}>  8. Відповідальність сторін</Typography>
                    <Typography variant="body1" style={{ margin: '15px' }}>  8.1. Всі суперечки і розбіжності, що виникають при виконанні сторонами зобов&#39;язань за цим
                        Договором, вирішуються шляхом переговорів. У разі неможливості їх усунення, сторони мають
                        право звернутися за судовим захистом своїх інтересів.</Typography>

                </Paper>
            </Container>
        </div >
    )
}

const mapStateToProps = state => {
    return {
        translation: state.translationReducer
    }
}

export default connect(mapStateToProps)(PublicOfferAgreement)
