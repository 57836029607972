import React, { useState } from "react"
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField
} from "@material-ui/core"

import { connect } from "react-redux"


import SnackBar from "./SnackBar"
import axios from "axios"



const DialogCall = ({ session, translation, pr, open, setOpen }) => {
    const [msg, setMsg] = useState("")
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")

    const [snackBarOpen, setSnackBarOpen] = useState(false)
    const [snackBarSeverity, setSnackBarSeverity] = useState("success")
    const [snackBarMessage, setSnackBarMessage] = useState(
        "Повідомлення успішно доставлено"
    )

    const getMsgLabel = () => {
        if (translation.language === "ua") {
            return `Текст повідомлення (${msg.length} / 256)`
        }
        if (translation.language === "ru") {
            return `Текст сообщения (${msg.length} / 256)`
        }
    }

    const getNameLabel = () => {
        if (translation.language === "ua") {
            return `Ваше ім'я (${name.length} / 64)`
        }
        if (translation.language === "ru") {
            return `Ваше имя (${name.length} / 64)`
        }
    }

    const getPhoneLabel = () => {
        if (translation.language === "ua") {
            return `Ваш номер телефону (${phone.length} / 64)`
        }
        if (translation.language === "ru") {
            return `Ваш номер телефона (${phone.length} / 64)`
        }
    }

    const getEmailLabel = () => {
        const getInsideLabel = () => {
            if (!validateEmail(email) && email !== "") {
                if (translation.language === "ua") {
                    return "Невірний формат"
                }
                if (translation.language === "ru") {
                    return "Неверный формат"
                }
            }

            return ""
        }

        if (translation.language === "ua") {
            return `Ваша пошта ${getInsideLabel()} (${email.length} / 64)`
        }
        if (translation.language === "ru") {
            return `Ваша почта ${getInsideLabel()} (${email.length} / 64)`
        }
    }

    // Validate email
    const validateEmail = email => {
        /* eslint-disable no-useless-escape */
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(email).toLowerCase())
    }

    const isValid = () => {
        if (session.isLoggedIn) {
            return msg !== ""
        } else {
            return name !== "" && (phone !== "" || validateEmail(email))
        }
    }

    const handleSend = () => {
        const data = new FormData()

        data.append("text", msg)
        data.append("name", name)
        data.append("phone", phone)
        data.append("email", email)

        axios
            .post(`/message/`, data, {
                withCredentials: true
            })
            .then(res => {
                console.log("RESPONSE IS ", res.data)
                setSnackBarOpen(true)
                setSnackBarSeverity("success")

                if (translation.language === "ua") {
                    setSnackBarMessage("Повідомлення успішно доставлено")
                } else {
                    setSnackBarMessage("Сообщение успешно доставлено")
                }
            })
            .catch(err => {
                setSnackBarOpen(true)
                setSnackBarSeverity("error")
                if (translation.language === "ua") {
                    setSnackBarMessage("Не вдалось доставити повідомлення")
                } else {
                    setSnackBarMessage("Не получилось доставить сообщение")
                }
            })
            .finally(setOpen(false))
    }

    const dialog = (
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
            <DialogTitle>
                {translation.language === "ua" && "Зворотній зв'язок"}
                {translation.language === "ru" && "Обратная связь"}
            </DialogTitle>
            <DialogContent>
                <TextField
                    fullWidth
                    margin="dense"
                    color="primary"
                    onChange={e => setMsg(e.target.value)}
                    inputProps={{ maxLength: 256 }}
                    multiline
                    label={getMsgLabel()}
                />
                {!session.isLoggedIn && (
                    <>
                        <TextField
                            fullWidth
                            margin="dense"
                            color="primary"
                            onChange={e => setName(e.target.value)}
                            label={getNameLabel()}
                            inputProps={{ maxLength: 64 }}
                        />
                        <TextField
                            fullWidth
                            margin="dense"
                            color="primary"
                            onChange={e => setPhone(e.target.value)}
                            inputProps={{ maxLength: 64 }}
                            label={getPhoneLabel()}
                        />
                        <TextField
                            fullWidth
                            margin="dense"
                            color="primary"
                            onChange={e => setEmail(e.target.value)}
                            label={getEmailLabel()}
                            inputProps={{ maxLength: 64 }}
                        />
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button variant="text" color="primary" onClick={() => setOpen(false)}>
                    {translation.language === "ua" && "Відміна"}
                    {translation.language === "ru" && "Отмена"}
                </Button>
                <Button
                    variant="contained"
                    disableElevation
                    color="primary"
                    onClick={handleSend}
                    disabled={!isValid()}
                >
                    {translation.language === "ua" && "Відправити"}
                    {translation.language === "ru" && "Отправить"}
                </Button>
            </DialogActions>
        </Dialog>
    )
    return (
        <>
            { dialog}
            < SnackBar
                open={snackBarOpen}
                onClose={() => setSnackBarOpen(false)}
                severity={snackBarSeverity}
                message={snackBarMessage}
            />
        </>
    )
}

const mapStateToProps = state => {
    return {
        translation: state.translationReducer,
        session: state.sessionReducer
    }
}

export default connect(mapStateToProps)(DialogCall)