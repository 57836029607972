import React from "react"
import { connect } from "react-redux"

import Header from "./../ui/Header"
import Container from "./../ui/Container"
import PageTitle from "./../ui/PageTitle"
import { Typography } from "@material-ui/core"
import "./Faq.css"

const Faq = ({ translation }) => {
  return (
    <div className="Faq">
      <Header />
      <PageTitle title="Faq" />
      <Container>
        <div className="Faq--content">
          <div className="Faq--row">
            <div className="Faq--title">
              <Typography variant="h5">
                {translation.language === "ua" && "Співпраця"}
                {translation.language === "ru" && "Сотрудничество"}
              </Typography>
            </div>
            {translation.language === "ua" && (
              <>
                <Typography variant="body1">
                  Запрошуємо до взаємовигідної співпраці: оптові компанії, аптеки, торгові мережі, власників роздрібних
                  магазинів, інтернет магазини.
                </Typography>
                <Typography variant="body1">
                  Компанія «Ява-Інтернешенл» здійснює оптовий продаж всієї представленої продукції. На сайті вказані
                  тільки роздрібні ціни. Для оптових покупців є спеціальні умови продажів. Вся продукція компанії
                  офіційно завезена в Україну і сертифікована. Наша компанія - імпортер і єдиний офіційний представник в
                  Україні брендів Dabur (Індія), Freeman (США), BoroRegular (Індія), Bebeco (Корея), Aroma Perfume
                  (Україна).
                </Typography>
              </>
            )}
            {translation.language === "ru" && (
              <>
                <Typography variant="body1">
                  Приглашаем к взаимовыгодному сотрудничеству: оптовые компании, аптеки, торговые сети, владельцев
                  розничных магазинов, интернет магазины.
                </Typography>
                <Typography variant="body1">
                  Компания «Ява-Интернешенл» осуществляет оптовую продажу всей представленной продукции. На сайте
                  указаны только розничные цены. Для оптовых покупателей имеются специальные условия продаж. Вся
                  продукция компании официально завезена в Украину и сертифицирована. Наша компания - импортер и
                  единственный официальный представитель в Украине брендов Dabur (Индия), Freeman (США), BoroRegular
                  (Индия), Bebeco (Корея), Aroma Perfume (Украина).
                </Typography>
              </>
            )}
          </div>
          <div className="Faq--row">
            {translation.language === "ua" && (
              <Typography variant="h6" className="Faq--last">
                З питань співпраці звертайтесь за номерами телефонів (067) 37 24 364, (097) 22 36 790, або заповніть форму
                на сторінці зворотного зв&#39;язку із зазначенням теми листа «Опт». При заповненні форми вкажіть свої
                контактні дані. Ми вам обов&#39;язково зателефонуємо.
              </Typography>
            )}
            {translation.language === "ru" && (
              <Typography variant="h6" className="Faq--last">
                По вопросам сотрудничества обращайтесь по телефонам (067) 37 24 364, (097) 22 36 790, или заполните форму
                на странице обратной связи с указанием темы письма «Опт». При заполнении формы укажите свои
                контактные данные Мы вам обязательно позвоним по телефону.
              </Typography>
            )}
          </div>
        </div>
      </Container>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    translation: state.translationReducer
  }
}

export default connect(mapStateToProps)(Faq)
