import React, { useEffect, useState } from "react"
import Autocomplete from "@material-ui/lab/Autocomplete"
import TextField from "@material-ui/core/TextField"
import axios from "axios"
import { connect } from "react-redux"


const SearchCityAutocomplete = props => {
  const [value, setValue] = useState(props.value)
  const [city, setCity] = useState('')
  const [options, setOptions] = useState([])

  useEffect(() => {
    axios('np/find_city/?query=' + city).then(res => {
      setOptions(res.data)
    })
  }, [city])

  const handleCityChange = e => {
    setCity(e.target.value)
  }

  return (
    <div style={{ width: "100%" }}>
      <Autocomplete
        value={value}
        options={options}
        autoHighlight
        filterSelectedOptions
        getOptionLabel={o => o.description_ua}
        getOptionSelected={(o, v) => o.id === v.id}
        onChange={(e, city) => {
          if (city) {
            setValue(city)
            props.onChange(city)
          }
        }}
        renderInput={renderParams => (
          <TextField label="Місто" {...renderParams} onChange={handleCityChange} helperText={props.translation.language === "ua" ? "Почніть вводити місто" : "Начните вводить город"} margin="dense" fullWidth />
        )}
      />
    </div>
  )
}



const mapStateToProps = state => {
  return {
    translation: state.translationReducer,
  }
}

export default connect(mapStateToProps, null)(SearchCityAutocomplete)