import React from "react"
import Header from "../../ui/Header"
import HomeCategory from "./HomeCategory"
import HomeSlider from "./HomeSlider"
import AboutUs from "../AboutUs"

const Home = () => {
  return (
    <div className="Home">
      <Header />
      <HomeSlider />
      <AboutUs isHomeScreen={true} />
      <HomeCategory />
    </div>
  )
}

export default Home
